import React from 'react';
import {
  Modal,
  CircularProgress,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  },
  top: {
    color: '#AD01F0',
    animationDuration: '500ms',
    marginTop: '10%'
  },
  circle: {
    strokeLinecap: 'round'
  },
  paper: {
    width: '25vw',
    marginLeft: '37.5vw',
    marginTop: '37.5vh',
    outline: 'none'
  },
  wrapper: {
    margin: 'auto',
    width: '50%',
    padding: '10%',
    textAlign: 'center'
  }
}));

export default function Loading({ text }) {
  const classes = useStyles();
  return (
    <Modal open={true}>
      <Paper className={classes.paper}>
        <div className={classes.wrapper}>
          <Typography variant="h5">Loading</Typography>
          <CircularProgress
            size={50}
            thickness={4}
            className={classes.top}
            classes={{ circle: classes.circle }}
          />
          {text && <p>{text}</p>}
        </div>
      </Paper>
    </Modal>
  );
}

Loading.propTypes = {
  text: PropTypes.string
};
