import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import PublishIcon from '@material-ui/icons/Publish';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  IconButton,
  makeStyles,
  Toolbar,
  Tooltip,
  Typography,
  lighten
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: 'theme.palette.secondary.main',
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 100%',
    fontWeight: 'bold'
  }
}));

export default function EnhancedTableToolbar(props) {
  const classes = useStyles();
  const {
    numSelected,
    objectName,
    onDeleteSinglesClicked,
    onDeleteBulkClicked,
    isPushSelected,
    onPublishClicked
  } = props;
  const FakeTransitionComponent = ({ children }) => children;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} id="tableTitle" component="div">
          No {objectName} selected
        </Typography>
      )}
      {numSelected > 0 ? (
        <>
          {isPushSelected ? (
            <>
              <Tooltip
                title="Push"
                TransitionComponent={FakeTransitionComponent}
              >
                <IconButton aria-label="push" onClick={onPublishClicked}>
                  <PublishIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Delete"
                TransitionComponent={FakeTransitionComponent}
              >
                <IconButton
                  aria-label="deleteAll"
                  onClick={onDeleteBulkClicked}
                >
                  <DeleteSweepIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Tooltip
              title="Delete"
              TransitionComponent={FakeTransitionComponent}
            >
              <IconButton aria-label="delete" onClick={onDeleteSinglesClicked}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  objectName: PropTypes.string.isRequired,
  onDeleteSinglesClicked: PropTypes.func.isRequired,
  onDeleteBulkClicked: PropTypes.func.isRequired,
  onPublishClicked: PropTypes.func.isRequired,
  isPushSelected: PropTypes.bool
};
