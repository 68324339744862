import React, { Fragment, useState, useEffect } from 'react';
import { Grid, Popper, makeStyles, Button, Link } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import AddDialog from '../AddDialog.jsx';
import RemoveDialog from '../RemoveDialog.jsx';
import { getAllowedDomains, removeAllowedDomain } from '../../services/api';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '20px'
  },
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    width: '100%',
    display: 'inline'
  },
  body: {
    overflow: 'auto',
    maxHeight: '80vh'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    cursor: 'pointer',
    align: 'center'
  },
  paper: {
    border: '1px solid',
    padding: 1,
    backgroundColor: 'white'
  },
  help: {
    fontSize: '16px',
    marginLeft: '5px',
    cursor: 'pointer'
  },
  row: {
    marginBottom: '10px'
  },
  text: {
    marginLeft: '20px'
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  buttonTop: {
    marginLeft: '20px'
  }
}));

export default function DomainsWhitelist() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [domains, setDomains] = useState([]);

  const getDomainsFromDB = () => {
    const domainsInDB = [];
    getAllowedDomains()
      .then((items) => {
        items.forEach((i) => domainsInDB.push(i));
      })
      .finally(() => {
        domainsInDB.sort((a, b) => a.domain.localeCompare(b.domain));
        setDomains(domainsInDB);
      });
  };

  const handleInfoClick = (event) => {
    setAnchorEl(anchorEl ? false : event.currentTarget);
  };

  const onAddRowClicked = () => {
    setShowDialog(true);
  };

  const onNewItemCancelClicked = () => {
    setShowDialog(false);
    getDomainsFromDB();
  };

  const onRemoveCallback = async (id) => {
    await removeAllowedDomain(id);
    getDomainsFromDB();
  };

  useEffect(() => {
    getDomainsFromDB();
  }, []);

  return (
    <Grid container alignItems="center" spacing={0}>
      <AddDialog
        onNewItemCancelClicked={onNewItemCancelClicked}
        show={showDialog}
      />
      <Popper open={!!anchorEl} anchorEl={anchorEl} placement={'left'}>
        <div className={classes.paper}>
          Trello, Twitter, Facebook, Bloomberg, Bloom and
          Trello-attachments.s3.amazonaws are accepted by default
        </div>
      </Popper>
      <>
        <Grid item xs={5} className={classes.info}>
          Domain
          <HelpOutlineIcon className={classes.help} onClick={handleInfoClick} />
        </Grid>
        <Grid item xs={5} align="right">
          <Button
            variant="contained"
            color="primary"
            onClick={onAddRowClicked}
            className={classes.buttonTop}
          >
            Add new domain to allowed list
          </Button>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <hr />
          </Grid>
        </Grid>
      </>
      <Grid container alignItems="center" spacing={2} className={classes.body}>
        {domains.map((url) => {
          return (
            <Fragment key={`div-${url.id}`}>
              <Grid
                item
                xs={7}
                key={`name-${url.domain}`}
                className={classes.text}
              >
                <Link>{url.domain}</Link>
              </Grid>
              <Grid
                item
                xs={2}
                key={`trash-${url.id}`}
                className={classes.icon}
              >
                <RemoveDialog
                  id={url.id}
                  name={url.domain}
                  onRemoveCallback={onRemoveCallback}
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
}
