import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Selector from '../Selector.jsx';
import useSelection from '../../helpers/useSelection.jsx';
import useLoading from '../../helpers/useLoading.jsx';
import ManageAccounts from './manageEnterprise/manageAccounts/ManageAccounts.jsx';
import { ManageAccountsOptions } from '../../helpers/SelectorOptions';
import Loading from '../Loading.jsx';

function renderSwitch(param, handleLoaded) {
  switch (param) {
    case 'Manage Trello Accounts':
      return <ManageAccounts handleLoaded={handleLoaded} />;
    default:
      return <ManageAccounts handleLoaded={handleLoaded} />;
  }
}

const initialValues = Object.freeze({
  SELECTION: 'Manage Trello Accounts'
});

export default function ManageEnterprise() {
  const { selection, handleChange } = useSelection(initialValues.SELECTION);
  const { loading, handleLoaded } = useLoading();

  useEffect(() => {
    handleLoaded(true);
  }, [selection]);

  return (
    <Grid container alignItems="center" spacing={0}>
      {loading && <Loading />}
      <Grid item xs={12}>
        <Selector
          selection={selection}
          handleChange={handleChange}
          options={ManageAccountsOptions}
        />
      </Grid>
      <Grid item xs={12}>
        {renderSwitch(selection, handleLoaded)}
      </Grid>
    </Grid>
  );
}
