import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { modifyUserGroup } from '../../../../services/api';
import { UsersSearchParams } from '../../../../helpers/SearchParams';
import SearchBar from '../../../SearchBar.jsx';

function EditUserGroupDialog(props) {
  const {
    handleLoaded,
    closeDialogs,
    userGroup,
    onError,
    show,
    selectedUsers,
    setSelectedUsers,
    users
  } = props;

  const [name, setName] = useState(userGroup?.group_name);
  const [filter, setFilter] = useState([]);
  const spaceValidation = !name.replace(/\s/g, '').length;
  const onCancelClick = () => {
    setFilter([]);
    closeDialogs();
  };

  const onOkClick = async () => {
    if (spaceValidation) {
      return;
    }
    handleLoaded(true);
    try {
      const { error } = await modifyUserGroup({
        id: userGroup.id,
        name,
        users: selectedUsers
      });
      if (!error?.message) {
        setFilter([]);
        setSelectedUsers([]);
        handleLoaded(false);
        closeDialogs();
      } else {
        onError(error);
      }
    } catch (e) {
      onError(e);
    }
  };

  const textChanged = (event) => {
    setName(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    const userId = event.target.value;
    const { checked } = event.target;

    if (checked && selectedUsers.indexOf(userId) === -1) {
      const newSelectedUsers = selectedUsers.concat(userId);
      setSelectedUsers(newSelectedUsers);
    } else {
      const newSelectedUsers = selectedUsers.filter((selected) => {
        return selected !== userId;
      });
      setSelectedUsers(newSelectedUsers);
    }
  };

  const verifyCheck = (trelloUserId) => {
    return selectedUsers.some((trelloId) => trelloUserId === trelloId);
  };

  const showingUsers = users.filter((user) =>
    selectedUsers.some((trelloId) => trelloId === user?.trelloUserId)
  );

  useEffect(() => {
    setSelectedUsers(userGroup?.users_json.users || []);
    setName(userGroup?.group_name);
  }, [show]);
  return (
    <>
      <Dialog
        open={show}
        onClose={onCancelClick}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'xl'}
      >
        <DialogTitle id="form-dialog-title">
          Editing {userGroup?.group_name || 'name not specified'}
        </DialogTitle>
        <DialogContent>
          <TextField
            error={spaceValidation}
            helperText={
              spaceValidation ? 'Group name cannot be blank or empty' : ''
            }
            label="User Group Name"
            defaultValue={`${userGroup?.group_name || ''}`}
            onChange={textChanged}
          />
          <DialogContentText>Search users to select them</DialogContentText>
          <FormGroup>
            <SearchBar
              data={users}
              availableParams={UsersSearchParams}
              setFilter={setFilter}
              label={'Users'}
            />

            {filter.length > 0 && (
              <DialogContentText>
                Showing current filter results
              </DialogContentText>
            )}
            {filter?.map((user) => {
              return (
                <FormControlLabel
                  key={`${user.trelloUserId}-filter`}
                  control={
                    <Checkbox
                      key={`${user.trelloUserId}-filter`}
                      onChange={handleCheckboxChange}
                      checked={verifyCheck(user.trelloUserId)}
                      name={user.userName}
                    />
                  }
                  label={user.userName}
                  value={user.trelloUserId}
                />
              );
            })}
            <DialogContentText>
              Showing currently selected users
            </DialogContentText>
            {showingUsers?.map((user) => {
              return (
                <FormControlLabel
                  key={`${user.trelloUserId}-selected`}
                  control={
                    <Checkbox
                      key={`${user.trelloUserId}-selected`}
                      checked
                      onChange={handleCheckboxChange}
                      name={user?.userName}
                    />
                  }
                  label={user.userName}
                  value={user.trelloUserId}
                />
              );
            })}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelClick} color="primary">
            Cancel
          </Button>
          <Button
            onClick={onOkClick}
            disabled={!selectedUsers?.length || spaceValidation}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

EditUserGroupDialog.propTypes = {
  users: PropTypes.array,
  userGroup: PropTypes.object,
  show: PropTypes.bool,
  closeDialogs: PropTypes.func,
  handleLoaded: PropTypes.func,
  onError: PropTypes.func,
  selectedUsers: PropTypes.array,
  setSelectedUsers: PropTypes.func
};

export default EditUserGroupDialog;
