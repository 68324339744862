import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';

export default function SimpleTableBody({ row, order }) {
  return (
    <TableBody>
      <TableRow>
        {order?.map((property) => (
          <TableCell key={property}>{row?.[property]}</TableCell>
        ))}
      </TableRow>
    </TableBody>
  );
}
SimpleTableBody.propTypes = {
  order: PropTypes.array,
  row: PropTypes.object
};
