import { useState, useCallback } from 'react';

export default function useSelection(InitialState) {
  const [selection, setSelection] = useState(InitialState);

  const handleChange = useCallback(
    (change) => {
      setSelection(change);
    },
    [selection]
  );

  return { selection, handleChange };
}
