import React from 'react';

const RedirectAuth = () => {
  setTimeout(() => {
    window.location = window.environment.REACT_APP_OAUTH_URL;
  }, 100);

  return <div></div>;
};

export default RedirectAuth;
