import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';

const totalLabel = 'Total Counting:';

const useStyles = makeStyles(() => ({
  selected: {
    color: '#AD01F0',
    marginLeft: '5px',
    marginRight: '5px',
    fontSize: '18px'
  },
  total: {
    color: '#036AA6',
    fontSize: '18px',
    marginLeft: '20px'
  },
  result: {
    fontSize: '20px'
  },
  notSelected: {
    color: '#979797',
    marginLeft: '5px',
    marginRight: '5px',
    fontSize: '18px'
  },
  gridSelected: {
    borderBottom: 'solid #AD01F0 5px'
  },
  grid: {
    borderBottom: 'solid #979797 1px',
    cursor: 'pointer'
  },
  countGrid: {
    borderBottom: 'solid #979797 1px',
    whiteSpace: 'pre'
  },
  filler: {
    borderBottom: 'solid #979797 1px',
    flexGrow: 1
  },
  container: {
    marginBottom: '10px',
    marginTop: '20px',
    marginLeft: '4px',
    marginRight: '4px'
  }
}));

export default function Selector({
  total,
  selection,
  handleChange,
  options,
  labels
}) {
  const classes = useStyles();
  const isGridSelected = (grid) => {
    return grid === selection ? classes.gridSelected : classes.grid;
  };
  const isLabelSelected = (label) => {
    return label === selection ? classes.selected : classes.notSelected;
  };
  return (
    <div className={classes.container}>
      <Grid container direction="row" spacing={0}>
        <Grid item xs={10}>
          <Grid container spacing={1}>
            {options.map((option) => {
              return (
                <Grid
                  item
                  key={option.id}
                  className={isGridSelected(option.label)}
                  onClick={() => handleChange(option.label)}
                >
                  <Typography className={isLabelSelected(option.label)}>
                    {option.label}
                  </Typography>
                </Grid>
              );
            })}
            {!!total && (
              <>
                <Grid item className={classes.countGrid}>
                  <Typography className={classes.total}>
                    {totalLabel}
                  </Typography>
                </Grid>
                <Grid item className={classes.countGrid}>
                  <Typography className={classes.result}>
                    {total}{' '}
                    {total > 1
                      ? labels?.PLURAL || 'Rows'
                      : labels?.SINGULAR || 'Row'}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item className={classes.filler} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
Selector.propTypes = {
  options: PropTypes.array.isRequired,
  total: PropTypes.number,
  labels: PropTypes.object,
  selection: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
};
