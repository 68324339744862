import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import { removeWhitelistedBoard } from '../../services/api';

const useStyles = makeStyles(() => ({
  inputRow: {
    marginBottom: '5px'
  },
  removeButton: {
    marginLeft: '5px'
  },
  readOnly: {
    backgroundColor: '#ededed'
  },
  boardName: {
    margin: '5px',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));

function BoardInput(props) {
  const classes = useStyles();
  const [idBoard, setIdBoard] = useState('');
  const [boardName, setBoardName] = useState('');
  const [open, setOpen] = React.useState(false);

  const onRemoveButtonClick = () => {
    if (idBoard !== '') {
      setOpen(true);
    }
  };

  const onDialogNoClick = () => {
    setOpen(false);
  };

  const onDialogYesClick = () => {
    removeWhitelistedBoard(idBoard).then(() => {
      props.onRemoveCallback(props.id);
      setOpen(false);
    });
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    setIdBoard(props.id);
    setBoardName(props.name);
  }, []);

  const removeDialog = (
    <Dialog
      open={open}
      onClose={onDialogNoClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Remove board from allowed list?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This will remove the board <b>{boardName}</b> from the allowed list,
          are you sure you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogNoClick} color="primary">
          No
        </Button>
        <Button onClick={onDialogYesClick} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {removeDialog}
      <Grid item xs={4} className={classes.inputRow}>
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          value={idBoard}
          InputProps={{
            readOnly: true
          }}
        />
      </Grid>
      <Grid item xs={5}>
        <Typography
          className={classes.boardName}
          onClick={() =>
            openInNewTab(`https://trello.com/b/${props.shortLink}`)
          }
        >
          {boardName}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.removeButton}
          onClick={onRemoveButtonClick}
          disabled={idBoard === ''}
        >
          Remove
        </Button>
      </Grid>
    </>
  );
}

BoardInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  shortLink: PropTypes.string,
  showAlert: PropTypes.func,
  onRemoveCallback: PropTypes.func
};

export default BoardInput;
