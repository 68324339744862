import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Authorize from './components/Authorize.jsx';
import Client from './components/Client.jsx';
import Authorized from './components/Authorized.jsx';
import RedirectAuth from './components/RedirectAuth.jsx';
import Dashboard from './components/Dashboard.jsx';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Client} />
        <Route exact path="/client" component={Client} />
        <Route exact path="/authorize" component={Authorize} />
        <Route exact path="/authorized" component={Authorized} />
        <Route exact path="/authorized-redirect" component={Authorized} />
        <Route exact path="/auth" component={RedirectAuth} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route
          exact
          path="/dashboard/bulk-add-users"
          render={(props) => <Dashboard {...props} show="BulkAddUsers" />}
        />
        <Route
          exact
          path="/dashboard/custom-fields"
          render={(props) => <Dashboard {...props} show="CustomFields" />}
        />
        <Route
          exact
          path="/dashboard/allowed-boards"
          render={(props) => <Dashboard {...props} show="BoardsWhitelist" />}
        />
        <Route
          exact
          path="/dashboard/overview"
          render={(props) => <Dashboard {...props} show="Overview" />}
        />
        <Route
          exact
          path="/dashboard/allowed-domains"
          render={(props) => <Dashboard {...props} show="DomainsWhitelist" />}
        />
        <Route
          exact
          path="/dashboard/manage-enterprise"
          render={(props) => <Dashboard {...props} show="ManageAccounts" />}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
