const CustomFieldsHeadCells = Object.freeze([
  {
    id: 'field',
    numeric: false,
    disablePadding: false,
    label: 'Field'
  },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
  {
    id: 'options',
    numeric: false,
    disablePadding: false,
    label: 'Options',
    isObject: true
  },
  { id: 'board', numeric: false, disablePadding: false, label: 'Board' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Last Updated'
  }
]);

const CustomFieldsPushHeadCells = Object.freeze([
  {
    id: 'field',
    numeric: false,
    disablePadding: false,
    label: 'Field'
  },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
  {
    id: 'options',
    numeric: false,
    disablePadding: false,
    label: 'Options',
    isObject: true
  },
  {
    id: 'board',
    numeric: true,
    disablePadding: false,
    label: 'Occurrences in Boards'
  },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Last Updated'
  }
]);

const OverviewUsersHeadCells = Object.freeze([
  { id: 'userName', numeric: false, disablePadding: false, label: 'Username' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  {
    id: 'noWorkspaces',
    numeric: true,
    disablePadding: false,
    label: '# of Workspaces'
  },
  {
    id: 'workspaces',
    numeric: false,
    disablePadding: false,
    label: 'Workspaces',
    isObject: true
  },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  {
    id: 'authorized',
    numeric: false,
    disablePadding: false,
    label: 'Authorized'
  },
  {
    id: 'authorizationTime',
    numeric: false,
    disablePadding: false,
    label: 'Authorization Timestamp'
  }
]);

const CustomFieldsPushReportHeaders = Object.freeze([
  { id: 'field', label: 'Field Name' },
  { id: 'type', label: 'Field Type' },
  { id: 'name', label: 'Board Name' },
  { id: 'url', label: 'Url to board' },
  { id: 'error', label: 'Description' }
]);

const OverviewBoardsHeadCells = Object.freeze([
  { id: 'board', numeric: false, disablePadding: false, label: 'Board Name' },
  {
    id: 'workspace',
    numeric: false,
    disablePadding: false,
    label: 'Workspace'
  },
  {
    id: 'visibilitySettings',
    numeric: false,
    disablePadding: false,
    label: 'Visibility Settings'
  },
  {
    id: 'totalMembers',
    numeric: true,
    disablePadding: false,
    label: 'Total Members'
  },
  {
    id: 'adminCount',
    numeric: true,
    disablePadding: false,
    label: 'Admin Count'
  },
  {
    id: 'boardStatus',
    numeric: false,
    disablePadding: false,
    label: 'Board Status'
  },
  {
    id: 'archiveDate',
    numeric: false,
    disablePadding: false,
    label: 'Archive Date'
  },
  {
    id: 'lastActivity',
    numeric: false,
    disablePadding: false,
    label: 'Last Activity'
  },
  {
    id: 'powerUps',
    numeric: false,
    disablePadding: false,
    label: 'Power-ups'
  }
]);

const BulkAddLogsHeaders = Object.freeze([
  { id: 'trello_display_Name', label: 'Organization Name' },
  { id: 'created_at', label: 'Date pushed' }
]);

export {
  CustomFieldsHeadCells,
  OverviewBoardsHeadCells,
  OverviewUsersHeadCells,
  CustomFieldsPushReportHeaders,
  BulkAddLogsHeaders,
  CustomFieldsPushHeadCells
};
