import React, { useState, useEffect } from 'react';
import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import EnhancedTableToolbar from '../../EnhancedToolbar.jsx';
import EnhancedTableHead from '../../EnhancedTableHead.jsx';
import EnhancedTableContent from '../../EnhancedTableContent.jsx';
import { getUsers, deleteUsers } from '../../../services/api';
import { OverviewUsersHeadCells } from '../../../helpers/Headers';
import { OverviewUsers as contentOrder } from '../../../helpers/TableContentOrder';
import DeleteUsersDialog from './DeleteUsersDialog.jsx';
import CSVButton from '../../CSVButton.jsx';
import SearchBar from '../../SearchBar.jsx';
import { OverviewUsersSearchParams } from '../../../helpers/SearchParams';

const initialValues = Object.freeze({
  ORDER: 'asc',
  ORDER_BY: 'userName',
  SELECTED: [],
  PAGE: 0,
  DENSE: false,
  ROWS_PER_PAGE: 100,
  DATA: [],
  OBJECT_NAME: 'users'
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  title: {
    flex: '1 1 100%',
    fontSize: '18px',
    fontWeight: 'bold'
  }
}));

export default function Users({ handleTotalChange, handleLoaded }) {
  const [order, setOrder] = useState(initialValues.ORDER);
  const [orderBy, setOrderBy] = useState(initialValues.ORDER_BY);
  const [selected, setSelected] = useState(initialValues.SELECTED);
  const [page, setPage] = useState(initialValues.PAGE);
  const [dense, setDense] = useState(initialValues.DENSE);
  const [rowsPerPage, setRowsPerPage] = useState(initialValues.ROWS_PER_PAGE);
  const [data, setData] = useState(initialValues.DATA);
  const [filter, setFilter] = useState(initialValues.DATA);
  const [show, setShow] = useState(false);
  const classes = useStyles();

  const rowCount = filter.length > 0 ? filter.length : data.length;
  const dataToUse = filter.length > 0 ? filter : data;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.userName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const { value } = event.target;
    const rpp = value === 'All' ? data.length : value;
    setRowsPerPage(rpp);
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const closeDialog = () => {
    setShow(false);
  };

  const openDialog = () => {
    setShow(true);
  };

  const getUsersInDB = () => {
    getUsers()
      .then((items) => {
        const d = items
          .map((i) => {
            const {
              user_name: userName,
              full_name: fullName,
              user_email: email,
              is_admin: isAdmin,
              id,
              trello_token: trelloToken,
              organizations,
              status,
              authorization_timestamp: authorizationTime
            } = i;
            const authorized = trelloToken ? 'Yes' : 'No';
            return {
              userName,
              fullName,
              email,
              authorized,
              isAdmin,
              status,
              workspaces: organizations,
              noWorkspaces: organizations?.workspaces?.length || 0,
              authorizationTime,
              id
            };
          })
          .sort((a, b) => a.userName.localeCompare(b.userName));
        setData(d);
        setFilter([]);
        setRowsPerPage(d.length);
      })
      .then(() => {
        handleLoaded(false);
      })
      .catch(() => {
        handleLoaded(false);
      });
  };

  useEffect(() => {
    handleTotalChange(filter.length > 0 ? filter.length : data.length);
  }, [data, filter]);

  useEffect(() => {
    if (dataToUse.length === 0) {
      setDense(true);
      getUsersInDB();
    }
  }, []);

  const deleteUsersFromDB = async () => {
    if (selected.length > 0) {
      handleLoaded(true);
      await deleteUsers(selected);
      setSelected([]);
      setShow(false);
      getUsersInDB();
    }
  };

  return (
    <>
      <Paper className={classes.paper}>
        <SearchBar
          reset={'users'}
          data={data}
          availableParams={OverviewUsersSearchParams}
          setFilter={setFilter}
          label={'Users'}
        />
        <EnhancedTableToolbar
          numSelected={selected.length}
          objectName={initialValues.OBJECT_NAME}
          canSelect={true}
          onDeleteSinglesClicked={openDialog}
        />
        {show && (
          <DeleteUsersDialog
            show={show}
            onOkClick={deleteUsersFromDB}
            onCancelClick={closeDialog}
            selectedUsers={selected}
          />
        )}
        <TableContainer style={{ maxHeight: '66vh' }}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowCount}
              headCells={OverviewUsersHeadCells}
              canSelect={true}
            />
            <TableBody>
              <EnhancedTableContent
                data={dataToUse}
                order={order}
                orderBy={orderBy}
                rowsPerPage={rowsPerPage}
                page={page}
                isSelected={isSelected}
                handleClick={handleClick}
                contentOrder={contentOrder}
                canSelect={true}
              />
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[dataToUse.length || 0, 25, 50, 100]}
          component="div"
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            renderValue: (value) => value
          }}
        />
      </Paper>
      {data.length > 0 && (
        <CSVButton
          headers={OverviewUsersHeadCells}
          data={dataToUse}
          name={'Users'}
        />
      )}
    </>
  );
}

Users.propTypes = {
  handleTotalChange: PropTypes.func,
  handleLoaded: PropTypes.func
};
