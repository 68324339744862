const CustomFieldsSearchParams = Object.freeze([
  {
    id: 0,
    label: 'Field',
    property: 'field',
    uniques: true
  },
  { id: 1, label: 'Type', property: 'type', uniques: true }
]);
const UsersSearchParams = Object.freeze([
  {
    id: 0,
    label: 'User Name',
    property: 'userName',
    uniques: true
  },
  { id: 1, label: 'Email', property: 'email', uniques: true },
  { id: 2, label: 'Trello User ID', property: 'trelloUserId', uniques: true }
]);
const OverviewUsersSearchParams = Object.freeze([
  {
    id: 0,
    label: 'User Name',
    property: 'userName',
    uniques: true
  },
  { id: 1, label: 'Email', property: 'email', uniques: true },
  {
    id: 2,
    label: 'Authorization Status',
    property: 'authorized',
    uniques: true
  }
]);
const BoardsSearchParams = Object.freeze([
  {
    id: 0,
    label: 'Board Name',
    property: 'board',
    uniques: true
  },
  {
    id: 1,
    label: 'Workspace',
    property: 'workspace',
    uniques: true
  },
  {
    id: 2,
    label: 'Visibility Settings',
    property: 'visibilitySettings',
    uniques: true
  },
  { id: 3, label: 'Board Status', property: 'boardStatus', uniques: true },
  {
    id: 4,
    label: 'Power-ups',
    property: 'powerUps',
    uniques: true
  }
]);

export {
  OverviewUsersSearchParams,
  CustomFieldsSearchParams,
  UsersSearchParams,
  BoardsSearchParams
};
