import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Selector from '../Selector.jsx';
import useSelection from '../../helpers/useSelection.jsx';
import useLoading from '../../helpers/useLoading.jsx';
import useUsersDialog from './bulkAddUsers/UserGroups/useUsersDialog.jsx';
import { BulkAddUsersSelectorOptions } from '../../helpers/SelectorOptions';
import Loading from '../Loading.jsx';
import UserGroup from './bulkAddUsers/UserGroups/UserGroups.jsx';
import PushUserGroup from './bulkAddUsers/PushUserGroups/PushUserGroup.jsx';
import { getUsers } from '../../services/api';

function renderSwitch(param, handleLoaded, users) {
  switch (param) {
    case 'User Groups':
      return <UserGroup handleLoaded={handleLoaded} users={users} />;
    case 'Push User Groups':
      return <PushUserGroup handleLoaded={handleLoaded} />;
    default:
      return <UserGroup handleLoaded={handleLoaded} users={users} />;
  }
}

const initialValues = Object.freeze({
  SELECTION: 'User Groups'
});

export default function BulkAddUsers() {
  const { selection, handleChange } = useSelection(initialValues.SELECTION);
  const { loading, handleLoaded } = useLoading();
  const { users, setUsers } = useUsersDialog(handleLoaded);

  const getUsersInDB = () => {
    getUsers().then((items) => {
      const d = items
        .map((i) => {
          const {
            full_name: userName,
            user_email: email,
            id,
            trello_user_id: trelloUserId,
            organizations
          } = i;
          return {
            userName,
            trelloUserId,
            email,
            workspaces: organizations,
            id
          };
        })
        .sort((a, b) => a.userName.localeCompare(b.userName));
      setUsers(d);
    });
  };

  useEffect(() => {
    handleLoaded(true);
    getUsersInDB();
  }, [selection]);

  return (
    <Grid container alignItems="center" spacing={0}>
      {loading && <Loading />}
      <Grid item xs={12}>
        <Selector
          total={0}
          selection={selection}
          handleChange={handleChange}
          options={BulkAddUsersSelectorOptions}
        />
      </Grid>
      <Grid item xs={12}>
        {renderSwitch(selection, handleLoaded, users)}
      </Grid>
    </Grid>
  );
}
