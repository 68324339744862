import React from 'react';
/* global TrelloPowerUp */
import { getBoardsAndStore } from '../services/api';

TrelloPowerUp.initialize({
  'authorization-status': (trelloPowerUp) => {
    return trelloPowerUp
      .get('member', 'private', 'authToken')
      .then((authToken) => {
        return { authorized: authToken != null };
      });
  },
  'show-authorization': (trelloPowerUp) => {
    return trelloPowerUp.popup({
      title: 'Authorize Account',
      url: '/authorize',
      height: 140
    });
  },
  'remove-data': (trelloPowerUp) => {
    return trelloPowerUp.getAll().then((data) => {
      const user = { userId: data.member.private.authToken };
      const options = {
        method: 'POST',
        body: JSON.stringify(user),
        headers: {
          'Content-Type': 'application/json'
        }
      };
      fetch(`${window.environment.REACT_APP_API_URL}/disable-webhook`, options);
    });
  },
  'board-buttons': async (trelloPowerUp) => {
    const buttons = [
      {
        icon: {
          dark: 'https://cdn-icons-png.flaticon.com/512/3214/3214674.png',
          light: 'https://cdn.iconscout.com/icon/free/png-256/url-9-437256.png'
        },
        text: 'Bloomberg Enterprise Admin UI',
        condition: 'admin',
        callback: () => {
          return trelloPowerUp.modal({
            title: 'Bloomberg Enterprise',
            url: '/dashboard',
            fullscreen: true
          });
        }
      }
    ];
    if (window.environment?.ALLOWED_USERS) {
      const member = await trelloPowerUp.member('all');
      const allowedUsers = window.environment?.ALLOWED_USERS.split(',');
      if (allowedUsers.includes(member?.username))
        buttons.push({
          icon: {
            dark:
              'https://site-assets.fontawesome.com/releases/v6.1.0/svgs/light/wrench.svg',
            light:
              'https://site-assets.fontawesome.com/releases/v6.1.0/svgs/solid/wrench.svg'
          },
          text: 'Update Admin UI/Run Garbage Collector',
          condition: 'admin',
          callback: () => getBoardsAndStore()
        });
    }
    return buttons;
  }
});

const Client = () => {
  return <div></div>;
};

export default Client;
