import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { getUserGroups, removeUserGroup } from '../../../../services/api';
import useUsersDialog from './useUsersDialog.jsx';
import AddUsersDialog from './AddUsersDialog.jsx';
import UserGroupCard from './UserGroupCard.jsx';
import ErrorDialog from '../../../ErrorDialog.jsx';
import EditUserGroupDialog from './EditUserGroupDialog.jsx';
import UserGroupConfirmDelete from './UserGroupConfirmDelete.jsx';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  buttonTop: {
    marginBottom: '15px'
  }
}));

export default function UserGroups({ handleLoaded, users }) {
  const {
    addUsersClick,
    editUsersClick,
    closeDialogs,
    handleDelete,
    handleCancelDelete,
    userGroupToDelete,
    error,
    editUsersDialog,
    data,
    selectUsersDialog,
    selectedUsers,
    setData,
    onError,
    closeErrorDialog,
    setSelectedUsers
  } = useUsersDialog(handleLoaded);

  const classes = useStyles();

  const getUserGroupsInDB = () => {
    getUserGroups()
      .then((items) => {
        const { userGroups } = items;
        const d = userGroups.map((i) => i);
        setData(d);
      })
      .finally(() => {
        handleLoaded(false);
      })
      .catch(() => {
        handleLoaded(false);
      });
  };

  const removeUserGroupInDB = async (id) => {
    await handleLoaded(true);
    removeUserGroup(id)
      .then(() => {
        getUserGroupsInDB();
      })
      .finally(() => {
        handleLoaded(false);
      })
      .catch(() => {
        handleLoaded(false);
      });
  };

  useEffect(() => {
    getUserGroupsInDB();
  }, []);

  const handleCloseDialogs = () => {
    closeDialogs();
    getUserGroupsInDB();
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonTop}
            onClick={addUsersClick}
          >
            Add a new User Group
          </Button>
        </Grid>
        {selectUsersDialog && (
          <AddUsersDialog
            show={selectUsersDialog}
            users={users}
            handleLoaded={handleLoaded}
            closeDialogs={handleCloseDialogs}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            onError={onError}
            label={`Group${data.length + 1}`}
          />
        )}
        {editUsersDialog && (
          <EditUserGroupDialog
            show={!!editUsersDialog}
            users={users}
            userGroup={editUsersDialog}
            handleLoaded={handleLoaded}
            closeDialogs={handleCloseDialogs}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            onError={onError}
            label={`Group${data.length + 1}`}
          />
        )}
        {userGroupToDelete && (
          <UserGroupConfirmDelete
            show={!!userGroupToDelete?.group_name}
            userGroup={userGroupToDelete}
            deleteGroup={removeUserGroupInDB}
            handleDialogClose={handleCancelDelete}
          />
        )}
      </Grid>

      <Grid container spacing={2}>
        {users.length > 0 &&
          data?.map((userGroup) => {
            return (
              <Grid item xs={6} key={userGroup.id}>
                <UserGroupCard
                  key={userGroup.id}
                  users={users}
                  userGroup={userGroup}
                  remove={handleDelete}
                  edit={editUsersClick}
                />
              </Grid>
            );
          })}
      </Grid>

      <ErrorDialog
        show={!!error}
        error={error}
        onError={onError}
        closeErrorDialog={closeErrorDialog}
      />
    </>
  );
}

UserGroups.propTypes = {
  handleLoaded: PropTypes.func,
  users: PropTypes.array
};
