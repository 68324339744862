import React from 'react';
import { TableCell, TableRow, Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getComparator, stableSort } from '../helpers/sorters';
import EnhancedMenu from './EnhancedMenu.jsx';

export default function EnhancedTableContent(props) {
  const {
    data,
    contentOrder,
    orderBy,
    page,
    rowsPerPage,
    handleClick,
    isSelected,
    order,
    canSelect
  } = props;

  const Cells = () =>
    stableSort(data, getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, index) => {
        const isItemSelected = isSelected(row);
        const labelId = `enhanced-table-checkbox-${index}`;
        return (
          <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
          >
            {canSelect && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                  onClick={(event) => {
                    if (canSelect) handleClick(event, row);
                  }}
                />
              </TableCell>
            )}
            {contentOrder.map((item, i) => {
              if (typeof row[item] === 'object' && row[item] !== null)
                return (
                  <TableCell
                    key={`${row[item][Object.keys(row[item])[0]]}-${
                      row.id
                    }-${i}`}
                  >
                    <EnhancedMenu
                      items={row[item]?.workspaces || row[item]?.options}
                      id={row?.id?.toString()}
                      key={`${row[item] || row.id}-${row.id}-${i}`}
                    />
                  </TableCell>
                );
              return i === 0 ? (
                <TableCell
                  padding={canSelect ? 'none' : 'normal'}
                  component="th"
                  id={labelId}
                  scope="row"
                  key={`${row[item] || row.userName}-${row.id}-${i}`}
                >
                  {row[item]}
                </TableCell>
              ) : (
                <TableCell
                  align={typeof row[item] === 'number' ? 'right' : 'left'}
                  key={`${row[item] || row.userName}-${row.id}-${i}`}
                >
                  {row[item]}
                </TableCell>
              );
            })}
          </TableRow>
        );
      });
  return <Cells />;
}
EnhancedTableContent.propTypes = {
  data: PropTypes.array.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  contentOrder: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  isSelected: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  canSelect: PropTypes.bool.isRequired
};
