const CustomFields = Object.freeze([
  'field',
  'type',
  'options',
  'board',
  'status',
  'updatedAt'
]);

const OverviewUsers = Object.freeze([
  'userName',
  'email',
  'noWorkspaces',
  'workspaces',
  'status',
  'authorized',
  'authorizationTime'
]);

const OverviewBoards = Object.freeze([
  'board',
  'workspace',
  'visibilitySettings',
  'totalMembers',
  'adminCount',
  'boardStatus',
  'archiveDate',
  'lastActivity',
  'powerUps'
]);

const BulkAddLogs = Object.freeze(['trello_display_name', 'created_at']);

export { CustomFields, OverviewUsers, OverviewBoards, BulkAddLogs };
