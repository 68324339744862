import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import { manageAccounts, deactivateUsers } from '../../../../services/api';
import UserCard from './UserCard.jsx';
import ConfirmationDialog from './ConfirmationDialog.jsx';

export default function ManageAccounts(props) {
  const { handleLoaded } = props;
  const [users, setUsers] = useState([]);
  const [licenses, setLicenses] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [results, setResults] = useState('');

  const availability =
    (licenses?.maxMembers || 4000) - (licenses?.totalMembers || 0);

  useEffect(() => {
    if (users?.length > 0) handleLoaded(false);
  }, [users?.length]);

  useEffect(() => {
    const getInfo = async () => {
      const {
        users: DBUsers,
        licensesStatus: { licenses: apiLicenses }
      } = await manageAccounts();
      setUsers(DBUsers);
      setLicenses(apiLicenses);
    };
    getInfo();
  }, []);

  const handleOnChange = (newSelectedUsers) => {
    const found = newSelectedUsers.map((user) =>
      users.find(
        (u) =>
          (u.user_email === user ||
            u.full_name === user ||
            u.user_name === user) &&
          u.status === 'Licensed' &&
          u.is_admin !== true
      )
    );
    const onlyValidUsers = found.filter((user) => user);

    setSelectedUsers([...new Set(onlyValidUsers)]);
  };

  const handleOnDeactivate = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDeactivate = async () => {
    handleLoaded(true);
    const { results: apiResults } = await deactivateUsers(selectedUsers);
    const {
      licensesStatus: { licenses: apiLicenses }
    } = await manageAccounts();
    setLicenses(apiLicenses);
    setSelectedUsers([]);
    handleLoaded(false);
    setShow(false);
    const resultString = apiResults?.some((result) => result !== 200)
      ? 'Something went wrong'
      : 'Deactivation ended successfully';

    setResults(resultString);
  };

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={0}>
      {show && (
        <ConfirmationDialog
          length={selectedUsers?.length}
          show={show}
          handleClose={handleClose}
          handleOk={handleDeactivate}
        />
      )}
      {licenses?.totalMembers !== undefined && (
        <>
          <Grid container spacing={0} justifyContent="center">
            <Grid item xs={3} style={{ margin: 25 }}>
              <Typography>Available licenses : {availability}</Typography>
            </Grid>
            <Grid item xs={3} style={{ margin: 25 }}>
              <Typography>Current member numbers:</Typography>
              <Typography style={{ margin: '0 20px' }}>
                Licensed : {licenses?.count?.licensed}
              </Typography>
              <Typography style={{ margin: '0 20px' }}>
                Free or Managed : {licenses?.count?.freeOrManaged}
              </Typography>
              <Typography style={{ margin: '0 20px' }}>
                Board Guests : {licenses?.count?.boardGuests}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      <Grid item xs={6}>
        <div
          style={{
            maxHeight: '400px',
            height: '400px',
            overflow: 'auto',
            marginTop: 20
          }}
        >
          <TextareaAutosize
            style={{ width: '100%' }}
            aria-label="empty textarea"
            placeholder="Copy-Paste from excel the users you want to deactivate"
            onChange={(e) => handleOnChange(e?.target?.value?.split('\n'))}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <div
          style={{
            maxHeight: '400px',
            height: '400px',
            overflow: 'auto'
          }}
        >
          {selectedUsers?.length > 0 &&
            selectedUsers.map((user) => (
              <div key={user.id} style={{ margin: 10 }}>
                <UserCard
                  key={user.id}
                  name={`${user.full_name} (${user.status})`}
                  email={user.user_email}
                  userName={user.user_name}
                />
              </div>
            ))}
        </div>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="contained"
          color="secondary"
          style={{ width: '100%', marginTop: '20px', marginRight: 20 }}
          onClick={() => {
            handleOnDeactivate();
          }}
          disabled={selectedUsers?.length === 0}
        >
          Deactivate ({selectedUsers?.length}) user
          {selectedUsers?.length === 1 ? '' : 's'}
        </Button>
      </Grid>
      {results !== '' && (
        <Grid item xs={12}>
          <div style={{ display: 'flex', marginTop: 20 }}>
            <Typography style={{ margin: 'auto' }}>{results}</Typography>
          </div>
        </Grid>
      )}
    </Grid>
  );
}

ManageAccounts.propTypes = {
  handleLoaded: PropTypes.func
};
