import { useState } from 'react';

const InitialState = false;
export default function useDeletes() {
  const [deleteSingleDialog, setDeleteSingleDialog] = useState(InitialState);
  const [deleteBulkDialog, setDeleteBulkDialog] = useState(InitialState);

  const deleteSingleClicked = () => {
    setDeleteSingleDialog(true);
  };

  const deleteSingleCloseDialog = () => {
    setDeleteSingleDialog(false);
  };

  const deleteBulkClicked = () => {
    setDeleteBulkDialog(true);
  };

  const deleteBulkCloseDialog = () => {
    setDeleteBulkDialog(false);
  };

  return {
    deleteSingleDialog,
    deleteBulkDialog,
    deleteBulkClicked,
    deleteBulkCloseDialog,
    deleteSingleClicked,
    deleteSingleCloseDialog
  };
}
