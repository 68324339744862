import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function RemoveDialog(props) {
  const [idDomain, setIdDomain] = useState('');
  const [domain, setDomain] = useState('');
  const [open, setOpen] = React.useState(false);

  const onRemoveButtonClick = () => {
    if (idDomain !== '') {
      setOpen(true);
    }
  };

  const onDialogNoClick = () => {
    setOpen(false);
  };

  const onDialogYesClick = () => {
    props.onRemoveCallback(props.id);
    setOpen(false);
  };

  useEffect(() => {
    setIdDomain(props.id);
    setDomain(props.name);
  }, []);

  const removeDialog = (
    <Dialog
      open={open}
      onClose={onDialogNoClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Remove <b>{domain}</b> from allowed list?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This will remove the domain from the allowed list, and prevent adding
          more attachments from this domain <br />
          Do you want to remove it?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogNoClick} color="primary">
          No
        </Button>
        <Button onClick={onDialogYesClick} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {removeDialog}
      <Button
        variant="contained"
        style={{ backgroundColor: '#f50057', color: '#fff' }}
        onClick={onRemoveButtonClick}
      >
        Remove
      </Button>
    </>
  );
}

RemoveDialog.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  showAlert: PropTypes.func,
  onRemoveCallback: PropTypes.func
};

export default RemoveDialog;
