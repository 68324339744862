import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';

import AssignmentIcon from '@material-ui/icons/Assignment';
import LanguageIcon from '@material-ui/icons/Language';
import InputIcon from '@material-ui/icons/Input';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import GroupIcon from '@material-ui/icons/Group';
import ContactsIcon from '@material-ui/icons/Contacts';

import BoardsWhitelist from './dashboard/BoardsWhitelist.jsx';
import DomainsWhitelist from './dashboard/DomainsWhitelist.jsx';
import CustomFields from './dashboard/CustomFields.jsx';
import Overview from './dashboard/Overview.jsx';
import BulkAddUsers from './dashboard/BulkAddUsers.jsx';
import ManageEnterprise from './dashboard/ManageEnterprise.jsx';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  sideBar: {
    maxWidth: '300px',
    backgroundColor: '#F2F3F6',
    height: '100vh'
  },
  sideBarTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px'
  },
  sideBarMenuItem: {
    textAlign: 'left'
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3)
  }
}));

const renderSwitch = (param) => {
  switch (param) {
    case 'CustomFields':
      return <CustomFields />;
    case 'Overview':
      return <Overview />;
    case 'BoardsWhitelist':
      return <BoardsWhitelist />;
    case 'DomainsWhitelist':
      return <DomainsWhitelist />;
    case 'BulkAddUsers':
      return <BulkAddUsers />;
    case 'ManageAccounts':
      return <ManageEnterprise />;
    default:
      return <BoardsWhitelist />;
  }
};

function Dashboard(props) {
  const classes = useStyles();
  const [pageTitle, setPageTitle] = React.useState('');

  const linkItemList = (text, icon) => {
    const link = `/dashboard/${text.toLowerCase().replaceAll(' ', '-')}`;

    return (
      <ListItem
        button
        onClick={() => {
          setPageTitle(text);
        }}
        component={RouterLink}
        to={link}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
  };

  React.useEffect(() => {
    setPageTitle('Allowed Boards');
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            {pageTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="left"
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          {linkItemList('Allowed Boards', <AssignmentIcon />)}
          {linkItemList('Custom Fields', <InputIcon />)}
          {linkItemList('Overview', <GroupWorkIcon />)}
          {linkItemList('Allowed Domains', <LanguageIcon />)}
          {linkItemList('Bulk Add Users', <GroupIcon />)}
          {linkItemList('Manage Enterprise', <ContactsIcon />)}
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <>{renderSwitch(props.show)}</>
      </main>
    </div>
  );
}

Dashboard.propTypes = {
  show: PropTypes.string
};

export default Dashboard;
