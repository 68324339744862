import { useState } from 'react';

const InitialState = Object.freeze({
  OPEN: false,
  USERS: [],
  DATA: [],
  ERROR: '',
  OBJ: {}
});
export default function useUsersDialog(handleLoaded) {
  const [selectUsersDialog, setSelectUsersDialog] = useState(InitialState.OPEN);
  const [editUsersDialog, setEditUsersDialog] = useState(InitialState.OPEN);
  const [selectedUsers, setSelectedUsers] = useState(InitialState.USERS);
  const [data, setData] = useState(InitialState.DATA);
  const [userGroupToDelete, setUserGroupToDelete] = useState(InitialState.OBJ);
  const [users, setUsers] = useState(InitialState.DATA);
  const [error, setError] = useState(InitialState.ERROR);

  const addUsersClick = () => {
    setSelectUsersDialog(true);
  };

  const closeErrorDialog = () => {
    setError(InitialState.ERROR);
  };

  const onError = (err) => {
    handleLoaded(false);
    setError(`${err?.name} : ${err?.message}`);
  };

  const editUsersClick = (userGroup) => {
    setEditUsersDialog(userGroup);
  };
  const closeDialogs = () => {
    setSelectedUsers([]);
    setEditUsersDialog(false);
    setSelectUsersDialog(false);
  };

  const handleDelete = (userGroup) => {
    setUserGroupToDelete(userGroup);
  };

  const handleCancelDelete = () => {
    setUserGroupToDelete(InitialState.OBJ);
  };

  return {
    addUsersClick,
    editUsersClick,
    closeDialogs,
    handleDelete,
    handleCancelDelete,
    userGroupToDelete,
    users,
    error,
    editUsersDialog,
    data,
    selectUsersDialog,
    selectedUsers,
    setData,
    setUsers,
    onError,
    closeErrorDialog,
    setSelectedUsers
  };
}
