import { useState, useCallback } from 'react';

const InitialState = 0;

export default function useTotal() {
  const [total, setTotal] = useState(InitialState);

  const handleTotalChange = useCallback(
    (change) => {
      setTotal(change);
    },
    [total]
  );

  return { total, handleTotalChange };
}
