const CustomFieldsSelectorOptions = Object.freeze([
  { id: 'workspaces', label: 'Workspaces' },
  { id: 'enterprises', label: 'Push' }
]);

const OverviewSelectorOptions = Object.freeze([
  // This will be added when their pages are ready
  // { id: 'workspace', label: 'Workspace' },
  { id: 'boards', label: 'Boards' },
  { id: 'users', label: 'Users' }
]);

const BulkAddUsersSelectorOptions = Object.freeze([
  { id: 'userGroups', label: 'User Groups' },
  { id: 'pushGroups', label: 'Push User Groups' }
]);

export const ManageAccountsOptions = Object.freeze([
  { id: 'accounts', label: 'Manage Trello Accounts' }
]);

export {
  CustomFieldsSelectorOptions,
  OverviewSelectorOptions,
  BulkAddUsersSelectorOptions
};
