import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { pushUserGroupToOrgs } from '../../../../services/api';

function AddUsersDialog(props) {
  const { handleLoaded, closeDialogs, onError, show, organizations } = props;
  const userGroup = show;
  const usedOrganizations = userGroup.history.map((log) => log.org_trello_id);
  const [selectedOrgs, setSelectedOrgs] = useState(usedOrganizations);
  const [switchA, setSwitch] = useState(false);

  const onCancelClick = () => {
    closeDialogs();
  };

  const onOkClick = async () => {
    handleLoaded(true);
    try {
      const { error } = await pushUserGroupToOrgs(
        userGroup,
        selectedOrgs,
        switchA
      );
      if (!error?.message) {
        handleLoaded(false);
        closeDialogs();
      } else {
        onError(error);
      }
    } catch (e) {
      onError(e);
    }
  };

  const verifyCheck = (id) => selectedOrgs.some((org) => org === id);

  const handleChange = () => {
    setSwitch(!switchA);
  };

  const handleOrgCheckboxChange = (event) => {
    const organizationId = event.target.value;
    const { checked } = event.target;
    if (checked && selectedOrgs.indexOf(organizationId) === -1) {
      const newSelectedOrgs = selectedOrgs.concat(organizationId);
      setSelectedOrgs(newSelectedOrgs);
    } else {
      const newSelectedOrgs = selectedOrgs.filter((selected) => {
        return selected !== organizationId;
      });
      setSelectedOrgs(newSelectedOrgs);
    }
  };

  return (
    <>
      <Dialog
        open={!!show}
        onClose={onCancelClick}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'xl'}
      >
        <DialogTitle id="form-dialog-title">
          Push User Group to Workspaces
        </DialogTitle>
        <DialogContent>
          <Typography>Send email to all admins</Typography>
          <Switch
            checked={switchA}
            onChange={handleChange}
            name="mail"
            inputProps={{ 'aria-label': 'Receive Mail ' }}
          />
          <DialogContentText>Select Workspaces to be active</DialogContentText>
          <FormGroup>
            {organizations.map((org) => {
              return (
                <FormControlLabel
                  key={org.trello_id}
                  control={
                    <Checkbox
                      onChange={handleOrgCheckboxChange}
                      name={org.trello_display_name}
                      checked={verifyCheck(org.trello_id)}
                    />
                  }
                  label={org.trello_display_name}
                  value={org.trello_id}
                />
              );
            })}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelClick} color="primary">
            Cancel
          </Button>
          <Button
            onClick={onOkClick}
            disabled={!selectedOrgs?.length}
            color="primary"
          >
            Push
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AddUsersDialog.propTypes = {
  userGroup: PropTypes.object,
  organizations: PropTypes.array,
  show: PropTypes.object,
  label: PropTypes.string,
  closeDialogs: PropTypes.func,
  handleLoaded: PropTypes.func,
  onError: PropTypes.func
};

export default AddUsersDialog;
