/* eslint-disable import/prefer-default-export */

// Boards

const getBoards = () => {
  return fetch(`${window.environment.REACT_APP_API_URL}/boards`).then((data) =>
    data.json()
  );
};

const getWhitelistedBoards = () => {
  return fetch(
    `${window.environment.REACT_APP_API_URL}/board/whitelisted`
  ).then((data) => data.json());
};

const getBoardName = async (id) => {
  return fetch(`${window.environment.REACT_APP_API_URL}/board/name/${id}`).then(
    (data) => {
      if (data.status !== 404) {
        return data.json();
      }
      return null;
    }
  );
};

const getBoardsAndStore = () => {
  fetch(`${window.environment.REACT_APP_API_URL}/boards/store`);
};

const saveWhitelistedBoard = (id) => {
  const board = {
    id
  };
  const options = {
    method: 'POST',
    body: JSON.stringify(board),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return fetch(
    `${window.environment.REACT_APP_API_URL}/board/add-whitelist`,
    options
  );
};

const removeWhitelistedBoard = (id) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return fetch(`${window.environment.REACT_APP_API_URL}/board/${id}`, options);
};

//  Custom Fields

const getCustomFields = (idOrganization) => {
  const url = `${window.environment.REACT_APP_API_URL}/custom-fields${
    idOrganization !== 0 ? `/${idOrganization}` : ''
  }`;

  return fetch(url).then((data) => data.json());
};

const pushCustomFields = (customFields, workspaces) => {
  const data = {
    customFields,
    workspaces
  };
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };
  return fetch(
    `${window.environment.REACT_APP_API_URL}/custom-fields/push`,
    options
  ).then((ans) => {
    return ans.json();
  });
};

const removeCustomFields = (customFields) => {
  const data = {
    customFields
  };
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };
  return fetch(
    `${window.environment.REACT_APP_API_URL}/custom-fields/delete`,
    options
  ).then((ans) => {
    return ans.json();
  });
};

const bulkRemoveCustomFields = (customFields, workspaces) => {
  const data = {
    customFields,
    workspaces
  };
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };
  return fetch(
    `${window.environment.REACT_APP_API_URL}/custom-fields/bulk-delete`,
    options
  ).then((ans) => {
    return ans.json();
  });
};

// Domains

const getAllowedDomains = () => {
  return fetch(`${window.environment.REACT_APP_API_URL}/domains`).then(
    (data) => {
      return data.json();
    }
  );
};

const addAllowedDomain = (domain) => {
  const data = {
    domain
  };
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return fetch(`${window.environment.REACT_APP_API_URL}/domains`, options);
};

const removeAllowedDomain = (id) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return fetch(
    `${window.environment.REACT_APP_API_URL}/domains/${id}`,
    options
  );
};

//  Organizations

const getOrganizations = () => {
  return fetch(`${window.environment.REACT_APP_API_URL}/organizations`).then(
    (data) => {
      return data.json();
    }
  );
};

const getAllOrganizations = () => {
  return fetch(
    `${window.environment.REACT_APP_API_URL}/all-organizations`
  ).then((data) => {
    return data.json();
  });
};

//  Users

const getUsers = () => {
  return fetch(`${window.environment.REACT_APP_API_URL}/users`).then((data) => {
    return data.json();
  });
};

export const deleteUsers = (users) => {
  const data = {
    users
  };
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  };
  return fetch(`${window.environment.REACT_APP_API_URL}/user/delete`, options);
};

export const manageAccounts = () => {
  return fetch(`${window.environment.REACT_APP_API_URL}/manage-accounts`).then(
    (data) => {
      return data.json();
    }
  );
};

export const deactivateUsers = (users) => {
  const data = {
    users
  };
  const options = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return fetch(
    `${window.environment.REACT_APP_API_URL}/user/deactivate`,
    options
  ).then((res) => res.json());
};

// UserGroups
const getUserGroups = () => {
  return fetch(`${window.environment.REACT_APP_API_URL}/userGroups`).then(
    (data) => {
      return data.json();
    }
  );
};

const getUserGroup = (id) => {
  return fetch(`${window.environment.REACT_APP_API_URL}/userGroups/${id}`).then(
    (data) => {
      return data.json();
    }
  );
};

const addUserGroup = (name, users) => {
  const data = {
    name,
    users
  };
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return fetch(`${window.environment.REACT_APP_API_URL}/userGroups`, options);
};

const modifyUserGroup = (userGroup) => {
  const data = {
    userGroup
  };
  const options = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return fetch(
    `${window.environment.REACT_APP_API_URL}/userGroups/${userGroup.id}`,
    options
  );
};
const removeUserGroup = (id) => {
  const options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return fetch(
    `${window.environment.REACT_APP_API_URL}/userGroups/${id}`,
    options
  );
};

const pushUserGroupToOrgs = (userGroup, organizations, csv) => {
  const data = { userGroup, organizations, csv };
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return fetch(
    `${window.environment.REACT_APP_API_URL}/userGroups/${userGroup.id}/push`,
    options
  );
};

const removeUserGroupFromOrgs = (userGroupId, csv) => {
  const options = {
    method: 'DELETE',
    body: JSON.stringify({ data: { csv } }),
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return fetch(
    `${window.environment.REACT_APP_API_URL}/userGroups/${userGroupId}/remove`,
    options
  );
};

export const getEnterpriseMembership = () => {
  return fetch(
    `${window.environment.REACT_APP_API_URL}/enterprise/memberships`
  ).then((data) => data.json());
};

export {
  getBoards,
  getBoardName,
  getBoardsAndStore,
  getWhitelistedBoards,
  getOrganizations,
  getAllOrganizations,
  getAllowedDomains,
  addAllowedDomain,
  saveWhitelistedBoard,
  removeAllowedDomain,
  removeWhitelistedBoard,
  removeCustomFields,
  removeUserGroupFromOrgs,
  bulkRemoveCustomFields,
  getCustomFields,
  getUsers,
  pushCustomFields,
  addUserGroup,
  getUserGroup,
  getUserGroups,
  modifyUserGroup,
  removeUserGroup,
  pushUserGroupToOrgs
};
