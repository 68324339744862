import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';

export default function SimpleTableHead({ headers }) {
  return (
    <TableHead>
      <TableRow>
        {headers?.map((cell) => (
          <TableCell key={cell.id}>{cell?.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
SimpleTableHead.propTypes = {
  headers: PropTypes.array
};
