import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

function DeleteUsersDialog(props) {
  const { show, onOkClick, onCancelClick, selectedUsers } = props;

  const strings = Object.freeze({
    SINGLE: 'this selected user',
    MULTIPLE: `these ${selectedUsers.length} selected users`
  });

  const stringToUse =
    selectedUsers.length > 1 ? strings.MULTIPLE : strings.SINGLE;
  return (
    <>
      <Dialog
        open={show}
        onClose={onCancelClick}
        aria-labelledby="form-dialog-title"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">Delete Users from DB</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you click OK, {stringToUse} will be deleted from the database:
          </DialogContentText>
          {selectedUsers.map((user) => {
            return (
              <Typography gutterBottom key={user.id}>
                {user.fullName} [{user.userName}],
              </Typography>
            );
          })}
          <DialogContentText style={{ marginTop: 20 }}>
            Note that they will need to re-authorize the power-up to reappear,
            or until the cronjob adds them again
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelClick} color="primary">
            Cancel
          </Button>
          <Button onClick={onOkClick} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DeleteUsersDialog.propTypes = {
  show: PropTypes.bool,
  onCancelClick: PropTypes.func,
  onOkClick: PropTypes.func,
  selectedUsers: PropTypes.array
};

export default DeleteUsersDialog;
