import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getBoardName, saveWhitelistedBoard } from '../../services/api';

function NewInputDialog(props) {
  const [open, setOpen] = useState(false);
  const [addDisabled, setAddDisabled] = useState(true);
  const [boardName, setBoardName] = useState('');
  const [value, setValue] = useState('');

  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);

  const onNewBoardCancelClicked = () => {
    setValue('');
    setBoardName('');
    props.onNewBoardCancelClicked();
  };

  const onNewBoardAddClicked = async () => {
    const found = await getBoardName(value);
    setValue('');
    setAddDisabled(true);
    setBoardName('Board name');

    if (found) {
      saveWhitelistedBoard(value).then((addResponse) => {
        props.showAlert(addResponse.status);
        props.onNewBoardAddClicked();
      });
    } else {
      props.showAlert(404);
      props.onNewBoardAddClicked();
    }
  };

  const onIdBoardChanged = async (e) => {
    const idBoard = e.target.value;
    const name = await getBoardName(idBoard);

    setAddDisabled(idBoard === '');
    setValue(idBoard);

    if (name) {
      setBoardName(name);
    } else {
      setBoardName('Board not found');
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onNewBoardCancelClicked}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Add Board to allowed list
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please paste the Board ID you want to add to the Board allowed list
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="idBoard"
            label="Board Id"
            type="text"
            fullWidth
            onChange={onIdBoardChanged}
          />
          {!addDisabled && <DialogContentText>{boardName}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={onNewBoardCancelClicked} color="primary">
            Cancel
          </Button>
          <Button
            onClick={onNewBoardAddClicked}
            disabled={addDisabled}
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

NewInputDialog.propTypes = {
  show: PropTypes.bool,
  onNewBoardCancelClicked: PropTypes.func,
  onNewBoardAddClicked: PropTypes.func,
  showAlert: PropTypes.func
};

export default NewInputDialog;
