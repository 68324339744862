import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

function ConfirmDelete(props) {
  const { show, userGroup, deleteGroup, handleDialogClose } = props;
  const { group_name: name, id } = userGroup;
  const [switchA, setSwitch] = useState(false);

  const onCancelClick = () => {
    handleDialogClose();
  };

  const onOkClick = () => {
    handleDialogClose();
    deleteGroup(id, switchA);
  };

  const handleChange = () => {
    setSwitch(!switchA);
  };

  return (
    <>
      <Dialog
        open={show}
        onClose={onCancelClick}
        aria-labelledby="form-dialog-title"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          Remove {name || 'name not specified'} group from every board it was
          pushed to?
        </DialogTitle>
        <DialogContent>
          <Typography>Send email to admins</Typography>
          <Switch checked={switchA} onChange={handleChange} name="mail" />
          <DialogContentText>
            If you click OK, this group will be removed from all boards they
            were pushed to.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelClick} color="primary">
            Cancel
          </Button>
          <Button onClick={onOkClick} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ConfirmDelete.propTypes = {
  show: PropTypes.bool,
  deleteGroup: PropTypes.func,
  userGroup: PropTypes.object,
  handleDialogClose: PropTypes.func
};

export default ConfirmDelete;
