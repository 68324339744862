function descendingComparator(a, b, orderBy) {
  const aString = a[orderBy]?.toString().toLowerCase();
  const bString = b[orderBy]?.toString().toLowerCase();
  const stringCheck = (value) => Number.isNaN(value);
  const itemA = stringCheck(a) && stringCheck(b) ? aString : a[orderBy];
  const itemB = stringCheck(a) && stringCheck(b) ? bString : b[orderBy];
  if (itemB < itemA || itemA === undefined) {
    return -1;
  }
  if (itemB > itemA || itemB === undefined) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
