import React from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import SimpleTableHead from './SimpleTableHead.jsx';
import SimpleTableBody from './SimpleTableBody.jsx';

export default function SimpleTable(props) {
  const { order, rows, headers } = props;
  return (
    <TableContainer>
      <Table size="small">
        <SimpleTableHead headers={headers} />
        {rows?.map((row) => (
          <SimpleTableBody key={row.id} row={row} order={order} />
        ))}
      </Table>
    </TableContainer>
  );
}
SimpleTable.propTypes = {
  order: PropTypes.array,
  rows: PropTypes.array,
  headers: PropTypes.array
};
