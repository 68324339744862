import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import UserGroupHistory from './UserGroupHistory.jsx';
import usePushDialog from './usePushDialog.jsx';
import PushDialog from './PushDialog.jsx';
import {
  getUserGroups,
  getAllOrganizations,
  removeUserGroupFromOrgs
} from '../../../../services/api';
import ErrorDialog from '../../../ErrorDialog.jsx';
import ConfirmDelete from './ConfirmDelete.jsx';

export default function PushUserGroup({ handleLoaded }) {
  const {
    pushDialog,
    pushClick,
    closeErrorDialog,
    data,
    setData,
    organizations,
    setOrganizations,
    error,
    onError,
    closeDialogs,
    handleClear,
    handleCancelDelete,
    historyToDelete
  } = usePushDialog(handleLoaded);

  const getOrganizationsFromDB = () => {
    getAllOrganizations().then((items) => {
      const organizationsInApi = items.sort((a, b) =>
        a.trello_display_name.localeCompare(b.trello_display_name)
      );
      setOrganizations(organizationsInApi);
    });
  };

  const getUserGroupsInDB = () => {
    getUserGroups()
      .then((items) => {
        const { userGroups, bulkAddLogs } = items;
        const d = userGroups.map((i) => {
          const history =
            bulkAddLogs?.filter((log) => log.user_group_id === i.id) || [];
          return { ...i, history };
        });
        setData(d);
      })
      .finally(() => {
        handleLoaded(false);
      })
      .catch(() => {
        handleLoaded(false);
      });
  };

  const removeUserGroupHistory = async (id, csv) => {
    await handleLoaded(true);
    removeUserGroupFromOrgs(id, csv)
      .then(() => {
        getUserGroupsInDB();
      })
      .finally(() => {
        handleLoaded(false);
      })
      .catch(() => {
        handleLoaded(false);
      });
  };
  useEffect(() => {
    getOrganizationsFromDB();
    getUserGroupsInDB();
  }, []);

  const handlePush = (userGroup) => {
    pushClick(userGroup);
  };

  const handleCloseDialogs = () => {
    closeDialogs();
    getUserGroupsInDB();
  };

  return (
    <>
      <Grid container>
        {pushDialog && (
          <PushDialog
            show={pushDialog}
            organizations={organizations}
            handleLoaded={handleLoaded}
            closeDialogs={handleCloseDialogs}
            onError={onError}
          />
        )}

        {historyToDelete && (
          <ConfirmDelete
            show={!!historyToDelete?.group_name}
            userGroup={historyToDelete}
            deleteGroup={removeUserGroupHistory}
            handleDialogClose={handleCancelDelete}
          />
        )}
      </Grid>

      <Grid container spacing={2}>
        {data?.map((userGroup) => {
          return (
            <Grid item xs={6} key={userGroup.id}>
              <UserGroupHistory
                key={userGroup.id}
                history={userGroup.history}
                userGroup={userGroup}
                push={() => {
                  handlePush(userGroup);
                }}
                clear={handleClear}
              />
            </Grid>
          );
        })}
      </Grid>
      <ErrorDialog
        show={!!error}
        error={error}
        onError={onError}
        closeErrorDialog={closeErrorDialog}
      />
    </>
  );
}

PushUserGroup.propTypes = {
  handleLoaded: PropTypes.func
};
