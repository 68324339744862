import React, { useState, useEffect } from 'react';
import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  makeStyles
} from '@material-ui/core';
import PropTypes from 'prop-types';
import EnhancedTableHead from '../../EnhancedTableHead.jsx';
import EnhancedTableContent from '../../EnhancedTableContent.jsx';
import { getBoards } from '../../../services/api';
import { OverviewBoardsHeadCells } from '../../../helpers/Headers';
import { OverviewBoards as contentOrder } from '../../../helpers/TableContentOrder';
import CSVButton from '../../CSVButton.jsx';
import SearchBar from '../../SearchBar.jsx';
import { BoardsSearchParams } from '../../../helpers/SearchParams';

const initialValues = Object.freeze({
  ORDER: 'asc',
  ORDER_BY: 'board',
  SELECTED: [],
  PAGE: 0,
  DENSE: false,
  ROWS_PER_PAGE: 100,
  DATA: [],
  OBJECT_NAME: 'users'
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  title: {
    flex: '1 1 100%',
    fontSize: '18px',
    fontWeight: 'bold'
  },
  help: {
    fontSize: '16px',
    marginLeft: '5px',
    cursor: 'pointer'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

export default function Boards({ handleTotalChange, handleLoaded }) {
  const [order, setOrder] = useState(initialValues.ORDER);
  const [orderBy, setOrderBy] = useState(initialValues.ORDER_BY);
  const [selected, setSelected] = useState(initialValues.SELECTED);
  const [page, setPage] = useState(initialValues.PAGE);
  const [dense, setDense] = useState(initialValues.DENSE);
  const [rowsPerPage, setRowsPerPage] = useState(initialValues.ROWS_PER_PAGE);
  const [data, setData] = useState(initialValues.DATA);
  const [filter, setFilter] = useState(initialValues.DATA);
  const dataToUse = filter.length > 0 ? filter : data;
  const rowCount = dataToUse.length;
  const classes = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.userName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const { value } = event.target;
    const rpp = value === 'All' ? data.length : value;
    setRowsPerPage(rpp);
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const getBoardsInDB = () => {
    getBoards()
      .then((items) => {
        const d = items
          .map((i) => {
            const {
              id,
              board_name: board,
              organization_name: workspace,
              is_closed: isClosed,
              members,
              power_ups: powerUpsData,
              last_activity: lastActivity,
              admins,
              visibility_settings: visibilitySettings,
              archive_date: archiveDate
            } = i;
            const powerUps = powerUpsData?.powerUps?.join(' , ') || '';
            return {
              board,
              boardStatus: isClosed ? 'Closed' : 'Active',
              lastActivity,
              workspace: workspace || '',
              powerUps,
              totalMembers: members?.members?.length || 0,
              adminCount: admins?.admins?.length || 0,
              visibilitySettings: visibilitySettings || '',
              archiveDate,
              id
            };
          })
          .sort((a, b) => a.board.localeCompare(b.board));
        setData(d);
        setFilter([]);
        setRowsPerPage(d.length);
      })
      .then(() => {
        handleLoaded(false);
      })
      .catch(() => {
        handleLoaded(false);
      });
  };

  useEffect(() => {
    handleTotalChange(filter.length > 0 ? filter.length : data.length);
  }, [data, filter]);

  useEffect(() => {
    if (dataToUse.length === 0) {
      setDense(true);
      getBoardsInDB();
    }
  }, []);
  return (
    <>
      <Paper className={classes.paper}>
        <SearchBar
          reset={'boards'}
          data={data}
          availableParams={BoardsSearchParams}
          setFilter={setFilter}
          label={'Boards'}
        />

        <TableContainer style={{ maxHeight: '66vh' }}>
          <Table
            stickyHeader
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filter.length > 0 ? filter.length : data.length}
              headCells={OverviewBoardsHeadCells}
              canSelect={false}
            />
            <TableBody>
              <EnhancedTableContent
                data={filter.length > 0 ? filter : data}
                order={order}
                orderBy={orderBy}
                rowsPerPage={rowsPerPage}
                page={page}
                isSelected={isSelected}
                handleClick={handleClick}
                contentOrder={contentOrder}
                canSelect={false}
              />
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[dataToUse.length || 0, 25, 50, 100]}
          component="div"
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            renderValue: (value) => value
          }}
        />
      </Paper>
      {data.length > 0 && (
        <CSVButton
          headers={OverviewBoardsHeadCells}
          data={dataToUse}
          name={'Boards'}
        />
      )}
    </>
  );
}

Boards.propTypes = {
  handleTotalChange: PropTypes.func,
  handleLoaded: PropTypes.func
};
