import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  createTheme,
  ThemeProvider
} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Grid
} from '@material-ui/core';
import { getCustomFields, getAllOrganizations } from '../../services/api';
import Selector from '../Selector.jsx';
import useSelection from '../../helpers/useSelection.jsx';
import useOrganization from '../../helpers/useOrganization.jsx';
import useLoading from '../../helpers/useLoading.jsx';
import useTotal from '../../helpers/useTotal.jsx';
import {
  CustomFieldsHeadCells,
  CustomFieldsPushHeadCells
} from '../../helpers/Headers';
import DropDown from '../DropDown.jsx';
import EnhancedTableHead from '../EnhancedTableHead.jsx';
import EnhancedTableToolbar from '../EnhancedToolbar.jsx';
import EnhancedTableContent from '../EnhancedTableContent.jsx';
import { CustomFields as contentOrder } from '../../helpers/TableContentOrder';
import { CustomFieldsSelectorOptions } from '../../helpers/SelectorOptions';
import Loading from '../Loading.jsx';
import CSVButton from '../CSVButton.jsx';
import PushCFDialog from './PushCFDialog.jsx';
import DeleteCFDialog from './DeleteCFDialog.jsx';
import BulkDeleteCFDialog from './BulkDeleteCFDialog.jsx';
import SearchBar from '../SearchBar.jsx';
import ResultDialog from '../ResultDialog.jsx';
import { CustomFieldsSearchParams } from '../../helpers/SearchParams';
import useDeletes from '../../helpers/useDeletes.jsx';
import ErrorDialog from '../ErrorDialog.jsx';

const theme = createTheme({
  palette: {
    secondary: {
      // This is green.A700 as hex.
      main: '#4d01f0'
    }
  }
});

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  title: {
    flex: '1 1 100%',
    fontSize: '18px',
    fontWeight: 'bold'
  }
}));

const initialValues = Object.freeze({
  ORDER: 'asc',
  ORDER_BY: 'field',
  SELECTED: [],
  PAGE: 0,
  DENSE: false,
  ROWS_PER_PAGE: 25,
  DATA: [],
  STATE: {
    orgInputs: []
  },
  ERROR: '',
  OBJECT_NAME: 'custom fields',
  SELECTION: 'Workspaces',
  LABELS: {
    PLURAL: 'Workspace/Organization visible boards',
    SINGULAR: 'Workspace/Organization visible board'
  },
  PUSH_DIALOG: false,
  CF_PUSH_TEXT: 'Please do not close this window',
  REPORT_STATE: {
    show: false,
    info: [],
    responseText: ''
  }
});

export default function CustomFields() {
  const classes = useStyles();
  const { selection, handleChange } = useSelection(initialValues.SELECTION);
  const { organization, handleOrgChange } = useOrganization();
  const { total, handleTotalChange } = useTotal();
  const { loading, handleLoaded } = useLoading();
  const [order, setOrder] = useState(initialValues.ORDER);
  const [orderBy, setOrderBy] = useState(initialValues.ORDER_BY);
  const [selected, setSelected] = useState(initialValues.SELECTED);
  const [page, setPage] = useState(initialValues.PAGE);
  const [dense, setDense] = useState(initialValues.DENSE);
  const [rowsPerPage, setRowsPerPage] = useState(initialValues.ROWS_PER_PAGE);
  const [data, setData] = useState(initialValues.DATA);
  const [state, setState] = useState(initialValues.STATE);
  const [pushDialog, setPushDialog] = useState(initialValues.PUSH_DIALOG);
  const [filter, setFilter] = useState(initialValues.DATA);
  const [pushData, setPushData] = useState(initialValues.DATA);
  const [dbData, setDbData] = useState(initialValues.DATA);
  const [report, setReport] = useState(initialValues.REPORT_STATE);
  const [error, setError] = useState(initialValues.ERROR);
  const {
    deleteSingleDialog,
    deleteBulkDialog,
    deleteBulkClicked,
    deleteBulkCloseDialog,
    deleteSingleClicked,
    deleteSingleCloseDialog
  } = useDeletes();

  const dataToUse = filter.length > 0 ? filter : data;
  const rowCount = dataToUse.length;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const showReport = (info, responseText, label) => {
    setReport({ ...report, show: true, info, responseText, label });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds =
        filter.length > 0 ? filter.map((n) => n) : data.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const onError = (err) => {
    handleLoaded(false);
    setError(`${err?.name} : ${err?.message}`);
  };

  const handleClick = (event, item) => {
    const selectedIndex = selected.findIndex((cf) => {
      return (
        cf.field === item.field &&
        cf.type === item.type &&
        cf.board === item.board
      );
    });
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const { value } = event.target;
    const rpp = value === 'All' ? data.length : value;
    setRowsPerPage(rpp);
    setPage(0);
  };

  const isSelected = (item) => {
    const isFieldSelected = selected.findIndex((n) => {
      return (
        n.field === item.field && n.type === item.type && n.board === item.board
      );
    });
    return isFieldSelected !== -1;
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const getCustomFieldsInAPI = (idOrganization) => {
    getCustomFields(idOrganization)
      .then((items) => {
        const uniqueObject = {};
        const d = items.map((i) => {
          const {
            field_name: field,
            type,
            board_name: board,
            status,
            id,
            options,
            updated_at: updatedAt
          } = i;
          const tags = options?.options?.map((option) => {
            return { color: option?.color, text: option.value.text };
          });
          const tagString = JSON.stringify(tags);
          uniqueObject[`${field}${type}${tagString}`] = {
            field,
            type,
            board:
              (uniqueObject[`${field}${type}${tagString}`]?.board || 0) + 1,
            options,
            status,
            id,
            updatedAt
          };
          return {
            field,
            type,
            board,
            status,
            id,
            options,
            updatedAt
          };
        });
        const uniqueArray = Object.values(uniqueObject).map((item) => item);
        const boardsTotal = [...new Set(d.map((item) => item.board))].length;
        handleTotalChange(boardsTotal || 0);
        setPushData(uniqueArray);
        setDbData(d);
        setFilter([]);
        setRowsPerPage(d.length);
      })
      .then(() => {
        handleLoaded(false);
      })
      .catch(() => {
        handleLoaded(false);
      });
  };

  const getOrganizationsFromDB = () => {
    getAllOrganizations().then((items) => {
      const organizationsInApi = items.sort((a, b) =>
        a.trello_display_name.localeCompare(b.trello_display_name)
      );
      setState((prevState) => {
        return {
          ...prevState,
          orgInputs: organizationsInApi
        };
      });
      setFilter([]);
    });
  };

  const publishClicked = () => {
    setPushDialog(true);
  };

  const onPushCFDialogCancelClicked = () => {
    setPushDialog(false);
  };

  const onPushCFDialogOkClicked = () => {
    setPushDialog(false);
  };

  const closeErrorDialog = () => {
    setError(initialValues.ERROR);
  };

  const onResultDialogCloseClicked = () => {
    setReport(initialValues.REPORT_STATE);
    setSelected(initialValues.SELECTED);
    handleLoaded(true);
    getCustomFieldsInAPI(0);
  };

  useEffect(() => {
    setDense(true);
    getOrganizationsFromDB();
  }, []);

  useEffect(() => {
    setData(selection === 'Workspaces' ? dbData : pushData);
    setPage(0);
    handleLoaded(false);
  }, [dbData, pushData]);

  useEffect(() => {
    if (organization || organization === 0) {
      handleLoaded(true);
      getCustomFieldsInAPI(organization);
    }
  }, [organization]);

  useEffect(() => {
    setFilter(initialValues.DATA);
    setData(initialValues.DATA);
    setSelected(initialValues.SELECTED);
    handleLoaded(true);
    getCustomFieldsInAPI(0);
  }, [selection]);

  return (
    <ThemeProvider theme={theme}>
      {loading && (
        <Loading
          text={
            pushDialog || deleteSingleDialog ? initialValues.CF_PUSH_TEXT : ''
          }
        />
      )}
      {report?.show && (
        <ResultDialog
          show={report.show}
          text={report.responseText}
          data={report.info}
          label={report.label}
          onCloseClicked={onResultDialogCloseClicked}
        />
      )}
      <div className={classes.root}>
        <Grid container alignItems="center" spacing={0}>
          <Grid item xs={12}>
            <Selector
              handleChange={handleChange}
              selection={selection}
              options={CustomFieldsSelectorOptions}
              total={selection === 'Workspaces' ? total : null}
              labels={initialValues.LABELS}
            />
            {selection === 'Workspaces' && (
              <Grid item xs={4}>
                <DropDown
                  items={state.orgInputs}
                  handleChange={handleOrgChange}
                  organization={organization}
                  TransitionComponent={({ children }) => children}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Paper className={classes.paper}>
          <SearchBar
            reset={`${organization}-${selection}`}
            data={data}
            availableParams={CustomFieldsSearchParams}
            setFilter={setFilter}
            label={'Custom Fields'}
          />
          <EnhancedTableToolbar
            numSelected={selected.length}
            objectName={initialValues.OBJECT_NAME}
            canSelect={true}
            onDeleteSinglesClicked={deleteSingleClicked}
            onDeleteBulkClicked={deleteBulkClicked}
            onPublishClicked={publishClicked}
            isPushSelected={selection === 'Push'}
          />
          <TableContainer style={{ maxHeight: '55vh' }}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rowCount}
                headCells={
                  selection === 'Workspaces'
                    ? CustomFieldsHeadCells
                    : CustomFieldsPushHeadCells
                }
                canSelect={true}
              />
              <TableBody>
                <EnhancedTableContent
                  data={dataToUse}
                  order={order}
                  contentOrder={contentOrder}
                  orderBy={orderBy}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  isSelected={isSelected}
                  handleClick={handleClick}
                  canSelect={true}
                />
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, dataToUse.length || 0]}
            component="div"
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            SelectProps={{
              renderValue: (value) => value
            }}
          />
        </Paper>
        {data.length > 0 && (
          <CSVButton
            headers={
              selection === 'Workspaces'
                ? CustomFieldsHeadCells
                : CustomFieldsPushHeadCells
            }
            data={dataToUse}
            name={'CustomFields'}
          />
        )}
      </div>
      <ErrorDialog
        show={!!error}
        error={error}
        onError={onError}
        closeErrorDialog={closeErrorDialog}
      />
      <BulkDeleteCFDialog
        show={deleteBulkDialog}
        organizations={state.orgInputs}
        deleteSingleCloseDialog={deleteBulkCloseDialog}
        selectedCustomFields={selected}
        onError={onError}
        handleLoaded={handleLoaded}
        showReport={showReport}
      />
      <DeleteCFDialog
        show={deleteSingleDialog}
        deleteSingleCloseDialog={deleteSingleCloseDialog}
        selectedCustomFields={selected}
        handleLoaded={handleLoaded}
        onError={onError}
        showReport={showReport}
      />
      <PushCFDialog
        show={pushDialog}
        organizations={state.orgInputs}
        onPushCFDialogCancelClicked={onPushCFDialogCancelClicked}
        onPushCFDialogOkClicked={onPushCFDialogOkClicked}
        selectedCustomFields={selected}
        handleLoaded={handleLoaded}
        showReport={showReport}
        onError={onError}
      />
    </ThemeProvider>
  );
}
