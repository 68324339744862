import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function ErrorDialog(props) {
  const { error, closeErrorDialog: handleDialogClose, show } = props;

  const onOkClick = async () => {
    handleDialogClose();
  };

  return (
    <>
      <Dialog open={show} aria-labelledby="form-dialog-title" scroll="paper">
        <DialogTitle id="form-dialog-title">An error has occurred!</DialogTitle>
        <DialogContent>
          <DialogContentText>{error}</DialogContentText>
          <DialogContentText>
            Please take a screenshot of the error and send it to the support
            team
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onOkClick} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ErrorDialog.propTypes = {
  show: PropTypes.bool,
  handleLoaded: PropTypes.func,
  closeErrorDialog: PropTypes.func,
  error: PropTypes.string
};

export default ErrorDialog;
