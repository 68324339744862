import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
// import Box from '@material-ui/core/Box';

import blogo from '../assets/b.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    fontFamily: 'Arial',
    fontWeight: 'bold'
  },
  toolbar: {
    backgroundColor: '#5F809F',
    marginBottom: '30px'
  },
  bLogo: {
    backgroundColor: '#fff',
    width: '32px',
    marginRight: '5px'
  },
  appBar: {
    backgroundColor: '#fff'
  },
  blueBox: {
    color: '#6D8A8D',
    borderColor: '#6d8a8d',
    backgroundColor: '#f9ffff'
  },
  greenBox: {
    marginTop: '80px',
    color: '#274538',
    borderColor: '#6d8a8d',
    backgroundColor: '#fcfff6'
  },
  authorizationTitle: {
    marginBottom: '20px'
  },
  infoIcon: {
    width: '48px',
    color: 'green',
    marginTop: '8px'
  },
  username: {
    backgroundColor: '#FFFFE0',
    borderColor: 'rgba(94, 109, 132, 0.5)',
    padding: '2px',
    border: '1px solid'
  },
  returnToTrello: {
    fontSize: '2rem'
  }
}));

const Authorized = () => {
  const classes = useStyles();
  const location = useLocation();
  const isPopup = location.pathname === '/authorized';

  const getHashValue = (key) => {
    const matches = location.hash.match(new RegExp(`${key}=([^&]*)`));
    return matches ? matches[1] : null;
  };

  const isRejected = getHashValue('error') === 'Token%20request%20rejected';

  let authorize;

  try {
    if (window.opener && typeof window.opener.authorize === 'function') {
      authorize = window.opener.authorize;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  const authToken = getHashValue('token');
  if (authorize) {
    authorize(authToken);
  } else {
    localStorage.setItem('authToken', authToken);
  }
  const [username, setUsername] = useState('');
  const [isAllowed, setIsAllowed] = useState(false);

  const sendToken = (token) => {
    fetch(`${window.environment.REACT_APP_API_URL}/user`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token })
    })
      .then((response) => {
        if (isPopup) {
          setTimeout(() => {
            // window.close();
          }, 10000);
        }
        return response.json();
      })
      .then((data) => {
        if (
          /@bloomberg.net\s*|ib.quezada@encora.com|lee.hwilson@gmail.com|ernesto.tapia@encora.com|rommel.curiel@encora.com|diego.alcantara@encora.com$/.test(
            data.user_email
          )
        ) {
          setUsername(data.user_name);
          setIsAllowed(true);
        } else {
          setIsAllowed(false);
        }
      });
  };

  useEffect(() => {
    sendToken(authToken);
  }, []);

  if (isRejected) {
    setTimeout(() => {
      window.location = window.environment.REACT_APP_OAUTH_URL;
    }, 10000);
  }

  const successfullAuth = (
    <>
      <Typography align="left">
        <span>&#10003;</span> You have authorized Bloomberg Enterprise as{' '}
        <span className={classes.username}>@{username}</span>{' '}
      </Typography>
      <Typography align="left">
        We will now forward the authorization to Bloomberg Enterprise servers.
      </Typography>
    </>
  );

  const rejectedAuth = (
    <>
      <Typography align="left">
        You have <b>not</b> authorized Bloomberg Enterprise
      </Typography>
      <Typography align="left">
        You will soon be redirected back to the authorization page, or you can{' '}
        <a href={window.environment.REACT_APP_OAUTH_URL}>click here</a> to go
        there now.
      </Typography>
    </>
  );

  const notInEnterprise = (
    <>
      <Typography align="left">
        You are <b>not</b> allowed to use Bloomberg Enterprise
      </Typography>
      <Typography align="left">
        You will soon be redirected back to Trello, or you can{' '}
        <a href={'https://trello.com'}>click here</a> to go there now.
      </Typography>
    </>
  );
  let authStatusMessage = isRejected ? rejectedAuth : successfullAuth;
  if (!isAllowed) {
    authStatusMessage = notInEnterprise;
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <img src={blogo} className={classes.bLogo}></img>
          <Typography variant="h6" className={classes.title}>
            Bloomberg Enterprise Authorization
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container spacing={0} justify="center">
        <Grid item xs={4}>
          <Paper
            className={`${classes.blueBox} ${classes.paper}`}
            variant="outlined"
          >
            <Typography
              variant="h5"
              className={classes.authorizationTitle}
              align="left"
            >
              Trello User Authorization
            </Typography>
            {authStatusMessage}
          </Paper>
          {!isRejected && (
            <Grid align="center" style={{ marginTop: '20px' }}>
              <Typography className={classes.returnToTrello}>
                <a href="https://trello.com">Return to Trello</a>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Authorized;
