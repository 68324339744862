import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

export default function UserGroupCard({ users, userGroup, remove, edit }) {
  const usersToShow = users?.filter((user) =>
    userGroup?.users_json.users?.some(
      (trelloId) => trelloId === user.trelloUserId
    )
  );
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 100 }}>
      <CardContent>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={9}>
            <Typography color="textPrimary" variant="h4">
              {userGroup?.group_name || 'name not specified'}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            onClick={handleExpandClick}
            style={{ cursor: 'pointer' }}
          >
            <Typography color="textSecondary">Total Users:</Typography>
          </Grid>
          <Grid
            item
            xs={1}
            onClick={handleExpandClick}
            style={{ cursor: 'pointer' }}
          >
            <Avatar>{usersToShow?.length}</Avatar>
          </Grid>
        </Grid>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box style={{ height: '168px', width: '100%', overflow: 'auto' }}>
            <Grid container spacing={2}>
              {usersToShow?.map((user) => {
                return (
                  <Grid item xs={6} key={`${user.id}-${userGroup.id}-grid1`}>
                    <Typography
                      key={`${user.id}-${userGroup.id}-name`}
                      sx={{ mb: 1.5 }}
                      color="textPrimary"
                    >
                      {`${user.userName}`}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Collapse>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => edit(userGroup)}>
          Edit
        </Button>
        <Button
          style={{ color: '#f50057' }}
          size="small"
          onClick={() => remove(userGroup)}
        >
          Delete
        </Button>
        <Typography color="textSecondary" style={{ marginLeft: 'auto' }}>
          User Group ID: {userGroup.id}
        </Typography>
      </CardActions>
    </Card>
  );
}
UserGroupCard.propTypes = {
  users: PropTypes.array,
  userGroup: PropTypes.object,
  remove: PropTypes.func,
  edit: PropTypes.func
};
