import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { addAllowedDomain } from '../services/api';

export default function AddDialog({ show, onNewItemCancelClicked }) {
  const [open, setOpen] = useState(false);
  const [addDisabled, setAddDisabled] = useState(true);
  const [domain, setDomain] = useState('');

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const onNewItemAddClicked = async () => {
    await addAllowedDomain(domain);
    onNewItemCancelClicked();
  };

  function CheckIsValidDomain(string) {
    const re = new RegExp(/\b(([a-z0-9.\\-]{1,63})[.](\w{2,63}$))/);
    return !re.test(string);
  }

  const onItemNameChanged = async (e) => {
    const { value } = e.target;
    const isInvalid = CheckIsValidDomain(value);
    if (!isInvalid) {
      setDomain(value);
    }
    setAddDisabled(isInvalid);
  };

  return (
    <Dialog
      open={open}
      onClose={onNewItemCancelClicked}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Add Domain to allowed list
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please paste the domain (e.g. &quot;facebook.com&quot;) you want to
          add
        </DialogContentText>
        <DialogContentText>
          Remove anything before (e.g. &quot;http://www.&quot;)
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="idDomain"
          label="Domain"
          type="text"
          fullWidth
          inputProps={{
            maxLength: 127
          }}
          onChange={onItemNameChanged}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onNewItemCancelClicked} color="primary">
          Cancel
        </Button>
        <Button
          onClick={onNewItemAddClicked}
          disabled={addDisabled}
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddDialog.propTypes = {
  show: PropTypes.bool,
  onNewItemCancelClicked: PropTypes.func,
  showAlert: PropTypes.func
};
