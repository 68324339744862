import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

export default function UserCard({ name, email, userName }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Card sx={{ maxWidth: 50 }}>
      <CardContent>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={9}>
            <Typography
              color="textPrimary"
              variant="h5"
              onClick={handleExpandClick}
            >
              {name}
            </Typography>
          </Grid>
        </Grid>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ mb: 1.5 }} color="textPrimary">
                  User Name: {userName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ mb: 1.5 }} color="textPrimary">
                  Email : {email || 'not specified'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
}
UserCard.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  userName: PropTypes.string
};
