import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

export default function SearchBar({
  data,
  label,
  setFilter,
  availableParams,
  reset
}) {
  const [search, setSearch] = useState(0);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleInputChange = (value) => {
    setSearch(value);
    setFilter([]);
  };

  const handleUniques = (uniqueArray) => {
    const results = data.filter((item) =>
      uniqueArray.includes(item[availableParams[search].property])
    );
    setFilter(results);
  };

  const searchUniques = availableParams[search].uniques;

  const options = searchUniques
    ? [
        ...new Set(data.map((item) => item[availableParams[search].property]))
      ].sort((a, b) => a.localeCompare(b))
    : [];
  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={1}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel>Search by</InputLabel>
          <Select
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={search}
            onChange={(e) => handleInputChange(e.target.value)}
          >
            {availableParams?.map((param) => (
              <MenuItem value={param.id} key={param.id}>
                {param.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7}>
        <Autocomplete
          key={`${search}${reset}`}
          multiple
          filterSelectedOptions
          onChange={(event, newValue) => {
            if (searchUniques) {
              handleUniques(newValue);
            } else {
              setFilter(newValue);
            }
          }}
          id="search-bar"
          label="Search"
          options={searchUniques ? options : data}
          getOptionLabel={(option) =>
            option?.[availableParams[search]?.property] || option || 'NA'
          }
          style={{ width: '100%' }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option?.[availableParams[search]?.property] || option}
                key={
                  option
                    ? `${option?.[availableParams[search].property]}-${
                        option.id
                      }`
                    : `${index}`
                }
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={`Search ${label} by ${availableParams[search].label}`}
              variant="outlined"
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
SearchBar.propTypes = {
  label: PropTypes.string,
  reset: PropTypes.string,
  availableParams: PropTypes.array,
  data: PropTypes.array,
  setFilter: PropTypes.func
};
