import React from 'react';
import { CSVLink } from 'react-csv';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

function CSVButton({ headers, data, name }) {
  function showDisplayNames(object) {
    if (object === null) return '';
    const { workspaces } = object;
    const displayNames = workspaces?.map((space) => space.displayName);
    return displayNames?.join(', ');
  }
  const documentHeaders = headers.map((header) => {
    return { label: header.label, key: header.id };
  });

  function rowCreator(row) {
    const obj = {};
    headers.forEach((item) => {
      const result = () => {
        switch (item.id) {
          case 'workspaces':
            return showDisplayNames(row[item.id]) || '';
          case 'noWorkspaces':
            return row[item.id] === null ? 0 : row[item.id];
          default:
            return row[item.id];
        }
      };
      obj[item.id] = result();
    });
    return obj;
  }
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const filename = `BloombergTrello${name}-${month}-${day}-${year}.csv`;
  const CSVDocument = [];
  if (data?.length > 0)
    data?.forEach((row) => CSVDocument.push(rowCreator(row)));
  const csvReport = {
    data: CSVDocument,
    filename
  };

  return (
    <div style={{ paddingLeft: '3%' }}>
      <CSVLink
        {...csvReport}
        headers={documentHeaders}
        style={{ textDecoration: 'none' }}
      >
        <Button variant="contained">Download CSV</Button>
      </CSVLink>
    </div>
  );
}
export default CSVButton;
CSVButton.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array,
  name: PropTypes.string
};
