import { useState } from 'react';

const InitialState = Object.freeze({
  OPEN: false,
  DATA: [],
  ERROR: '',
  OBJ: {}
});
export default function useUsersDialog(handleLoaded) {
  const [pushDialog, setPushDialog] = useState(InitialState.OPEN);
  const [clearDialog, setClearDialog] = useState(InitialState.OPEN);
  const [data, setData] = useState(InitialState.DATA);
  const [historyToDelete, setHistoryToDelete] = useState(InitialState.OBJ);
  const [organizations, setOrganizations] = useState(InitialState.DATA);
  const [error, setError] = useState(InitialState.ERROR);

  const pushClick = (userGroup) => {
    setPushDialog(userGroup);
  };

  const closeErrorDialog = () => {
    setError(InitialState.ERROR);
  };

  const onError = (err) => {
    handleLoaded(false);
    setError(`${err?.name} : ${err?.message}`);
  };

  const onClearClick = (userGroup) => {
    setClearDialog(userGroup);
  };
  const closeDialogs = () => {
    setClearDialog(false);
    setPushDialog(false);
  };

  const handleClear = (userGroup) => {
    setHistoryToDelete(userGroup);
  };

  const handleCancelDelete = () => {
    setHistoryToDelete(InitialState.OBJ);
  };

  return {
    pushDialog,
    setData,
    pushClick,
    closeErrorDialog,
    clearDialog,
    data,
    historyToDelete,
    organizations,
    setOrganizations,
    error,
    onError,
    onClearClick,
    closeDialogs,
    handleClear,
    handleCancelDelete
  };
}
