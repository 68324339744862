import { useState, useCallback } from 'react';

const InitialState = "";

export default function useOrganization() {
  const [organization, setOrganization] = useState(InitialState);

  const handleOrgChange = useCallback(
    (event) => {
      setOrganization(event?.target?.value);
    },
    [setOrganization]
  );

  return { organization, handleOrgChange };
}
