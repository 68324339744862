import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  InputLabel,
  makeStyles,
  FormControl,
  Select
} from '@material-ui/core/';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'left'
  },
  formControl: {
    width: '100%',
    marginBottom: '20px'
  }
}));

export default function DropDown({ items, handleChange, organization = 0 }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Choose a workspace</InputLabel>
      <Select
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={organization}
        onChange={handleChange}
      >
        <MenuItem value={0}>Enterprise</MenuItem>
        {items?.map((org) => (
          <MenuItem value={org.trello_id} key={org.trello_id}>
            {org.trello_display_name} ({org.trello_name})
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

DropDown.propTypes = {
  handleChange: PropTypes.func,
  organization: PropTypes.string,
  items: PropTypes.array
};
