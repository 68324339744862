import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { removeCustomFields } from '../../services/api';

function DeleteCFDialog(props) {
  const [open, setOpen] = useState(false);

  const {
    handleLoaded,
    show,
    deleteSingleCloseDialog: handleDialogClose,
    selectedCustomFields,
    onError,
    showReport
  } = props;

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const onCancelClick = () => {
    handleDialogClose();
  };

  const onOkClick = async () => {
    handleLoaded(true);
    try {
      const { report, result, label, error } = await removeCustomFields(
        selectedCustomFields
      );
      if (!error?.message || !error?.name) {
        handleLoaded(false);
        showReport(report, result, label);
      } else {
        onError(error);
      }
    } catch (e) {
      onError(e);
    }
    handleDialogClose();
  };

  const strings = Object.freeze({
    SINGLE: 'this selected custom field',
    MULTIPLE: `these ${selectedCustomFields.length} selected custom fields`
  });

  const stringToUse =
    selectedCustomFields.length > 1 ? strings.MULTIPLE : strings.SINGLE;
  return (
    <>
      <Dialog
        open={open}
        onClose={onCancelClick}
        aria-labelledby="form-dialog-title"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">Delete Custom Fields</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you click OK, {stringToUse} will be deleted:
          </DialogContentText>
          {selectedCustomFields.map((customField) => {
            return (
              <Typography gutterBottom key={customField.id}>
                {`${customField.field} [${customField.type}],
                 from: ${customField.board} \n `}
              </Typography>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelClick} color="primary">
            Cancel
          </Button>
          <Button onClick={onOkClick} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DeleteCFDialog.propTypes = {
  show: PropTypes.bool,
  organizations: PropTypes.array,
  deleteSingleCloseDialog: PropTypes.func,
  handleLoaded: PropTypes.func,
  onError: PropTypes.func,
  showReport: PropTypes.func,
  selectedCustomFields: PropTypes.array
};

export default DeleteCFDialog;
