import React, { useState } from 'react';
import {
  Menu,
  MenuItem,
  Fade,
  makeStyles,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  buttonText: {
    color: '#3f51b5',
    cursor: 'pointer',
    padding: 0
  },
  menuItem: {
    cursor: 'default',
    pointerEvents: 'none'
  }
}));

export default function EnhancedMenu({ items, id }) {
  const classes = useStyles();
  const [anchor, setAnchor] = useState(null);

  const handleOpenMenuInterview = () => {
    setAnchor(document.getElementById(`menu-object-${id}`));
  };

  const handleCloseMenuInterview = () => {
    setAnchor(null);
  };

  if (items === undefined || items?.length === 0)
    return <Typography>None</Typography>;

  return (
    <>
      <div id={`menu-object-${id}`}>
        <Typography
          className={classes.buttonText}
          onClick={handleOpenMenuInterview}
        >
          {items[0]?.displayName ? 'View Workspaces' : 'View Options'}
        </Typography>
      </div>

      <Menu
        id="object"
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleCloseMenuInterview}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
      >
        {items?.map((item) => {
          return (
            <MenuItem
              key={item?.displayName || item?.id}
              className={classes.menuItem}
            >
              {item?.displayName ||
                `Color: ${item.color}, Text: ${item?.value?.text}`}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

EnhancedMenu.propTypes = {
  items: PropTypes.array,
  id: PropTypes.string
};
