import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import BoardInput from './BoardInput.jsx';
import { getWhitelistedBoards } from '../../services/api';
import NewInputDialog from './NewInputDialog.jsx';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  sideBar: {
    maxWidth: '300px',
    backgroundColor: '#F2F3F6',
    height: '100vh'
  },
  sideBarTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px'
  },
  sideBarMenuItem: {
    textAlign: 'left'
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  hidden: {
    display: 'none'
  },
  buttonTop: {
    marginBottom: '15px'
  }
}));

function Dashboard() {
  const classes = useStyles();
  const horizontal = 'center';
  const vertical = 'top';
  const [state, setState] = useState({
    boardsInputs: [],
    newBoardInputValue: '',
    openSuccess: false,
    openError: false,
    openInfo: false
  });
  const [newBoardDialog, setNewBoardDialog] = useState(false);

  const showAlert = (status) => {
    switch (status) {
      case 200:
        setState((prevState) => {
          return {
            ...prevState,
            openSuccess: true
          };
        });
        break;
      case 409:
        setState((prevState) => {
          return {
            ...prevState,
            openInfo: true
          };
        });
        break;
      case 404:
        setState((prevState) => {
          return {
            ...prevState,
            openError: true
          };
        });
        break;
      default:
        setState((prevState) => {
          return {
            ...prevState
          };
        });
        break;
    }
  };

  const onRemoveCallback = (idBoard) => {
    const result = state.boardsInputs.filter((b) => {
      return b.trello_model_id !== idBoard;
    });
    setState((prevState) => {
      return {
        ...prevState,
        boardsInputs: result
      };
    });
  };

  const getBoardsFromDB = () => {
    getWhitelistedBoards().then((items) => {
      const boardsInDb = [];
      items.forEach((i) => {
        boardsInDb.push(i);
      });

      setState((prevState) => {
        return {
          ...prevState,
          boardsInputs: boardsInDb
        };
      });
    });
  };

  useEffect(() => {
    getBoardsFromDB();
  }, []);

  const onAddRowClicked = () => {
    setNewBoardDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setState((prevState) => {
      return {
        ...prevState,
        openSuccess: false,
        openError: false,
        openInfo: false
      };
    });
  };

  const onNewBoardCancelClicked = () => {
    setNewBoardDialog(false);
  };

  const onNewBoardAddClicked = () => {
    getBoardsFromDB();
    setNewBoardDialog(false);
  };

  return (
    <Grid container alignItems="center" spacing={0}>
      <Grid container>
        <Grid item xs={4}>
          Board ID
        </Grid>
        <Grid item xs={5}>
          Board Name
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <hr />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={onAddRowClicked}
            className={classes.buttonTop}
          >
            Add a new board to allowed list
          </Button>
        </Grid>
      </Grid>
      <Grid container alignItems="center">
        {state.boardsInputs.map((b) => {
          return (
            <BoardInput
              key={b.trello_model_id}
              id={b.trello_model_id}
              name={b.board_name}
              shortLink={b.trello_short_link}
              showAlert={showAlert}
              onRemoveCallback={onRemoveCallback}
            />
          );
        })}
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={onAddRowClicked}>
            Add a new board to allowed list
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={state.openSuccess}
        autoHideDuration={2500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="success">
          Board added to allowed list!
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.openError}
        autoHideDuration={2500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="error">
          Board not found!
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.openInfo}
        autoHideDuration={2500}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity="info">
          Board already on allowed list!
        </Alert>
      </Snackbar>
      <NewInputDialog
        onNewBoardCancelClicked={onNewBoardCancelClicked}
        onNewBoardAddClicked={onNewBoardAddClicked}
        show={newBoardDialog}
        showAlert={showAlert}
      />
    </Grid>
  );
}

export default Dashboard;
