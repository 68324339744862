import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { pushCustomFields } from '../../services/api';

function NewInputDialog(props) {
  const [open, setOpen] = useState(false);
  const [selectedOrgs, setSelectedOrgs] = useState([]);
  const [enterpriseSelected, setEnterpriseSelected] = useState(false);
  const {
    handleLoaded,
    show,
    onPushCFDialogCancelClicked: handleDialogCancelClick,
    onPushCFDialogOkClicked: handleDialogOkClicked,
    selectedCustomFields,
    showReport,
    organizations,
    onError
  } = props;

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const onPushCFDialogCancelClicked = () => {
    setEnterpriseSelected(false);
    handleDialogCancelClick();
  };

  const onPushCFDialogOkClicked = async () => {
    setEnterpriseSelected(false);
    handleLoaded(true);
    try {
      const { report, result, label, error } = await pushCustomFields(
        selectedCustomFields,
        selectedOrgs
      );
      if (!error?.message) {
        setSelectedOrgs([]);
        handleLoaded(false);
        showReport(report, result, label);
        handleDialogOkClicked();
      } else {
        onError(error);
      }
    } catch (e) {
      onError(e);
    }
  };

  const handleOrgCheckboxChange = (event) => {
    const organizationId = event.target.value;
    const { checked } = event.target;

    setEnterpriseSelected(organizationId === 'enterprise' && checked);
    if (checked && selectedOrgs.indexOf(organizationId) === -1) {
      const newSelectedOrgs = selectedOrgs.concat(organizationId);
      setSelectedOrgs(newSelectedOrgs);
    } else {
      const newSelectedOrgs = selectedOrgs.filter((selected) => {
        return selected !== organizationId;
      });
      setSelectedOrgs(newSelectedOrgs);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onPushCFDialogCancelClicked}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Push Custom Fields</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Where do you want to push custom fields?
          </DialogContentText>
          <FormGroup>
            {/* <FormControlLabel
              control={
                <Checkbox
                  onChange={handleOrgCheckboxChange}
                  name="enterprise"
                />
              }
              label="Enterprise"
              value="enterprise"
            /> */}
            {organizations.map((org) => {
              return (
                <FormControlLabel
                  key={org.trello_id}
                  control={
                    <Checkbox
                      onChange={handleOrgCheckboxChange}
                      name={org.trello_display_name}
                      disabled={enterpriseSelected}
                    />
                  }
                  label={org.trello_display_name}
                  value={org.trello_id}
                />
              );
            })}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onPushCFDialogCancelClicked} color="primary">
            Cancel
          </Button>
          <Button
            onClick={onPushCFDialogOkClicked}
            disabled={!selectedOrgs.length}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

NewInputDialog.propTypes = {
  show: PropTypes.bool,
  organizations: PropTypes.array,
  onPushCFDialogCancelClicked: PropTypes.func,
  onPushCFDialogOkClicked: PropTypes.func,
  handleLoaded: PropTypes.func,
  onError: PropTypes.func,
  showReport: PropTypes.func,
  selectedCustomFields: PropTypes.array
};

export default NewInputDialog;
