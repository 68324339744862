import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import SimpleTable from '../../../SimpleTable.jsx';
import { BulkAddLogs } from '../../../../helpers/TableContentOrder';
import { BulkAddLogsHeaders } from '../../../../helpers/Headers';

export default function UserGroupHistory({ userGroup, history, push, clear }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 100 }}>
      <CardContent>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={9}>
            <Typography color="textPrimary" variant="h4">
              {userGroup?.group_name || 'name not specified'}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            onClick={handleExpandClick}
            style={{ cursor: 'pointer' }}
          >
            <Typography color="textSecondary">Total Pushed:</Typography>
          </Grid>
          <Grid
            item
            xs={1}
            onClick={handleExpandClick}
            style={{ cursor: 'pointer' }}
          >
            <Avatar>{history?.length}</Avatar>
          </Grid>
        </Grid>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box style={{ height: '168px', width: '100%', overflow: 'auto' }}>
            <SimpleTable
              order={BulkAddLogs}
              rows={history}
              headers={BulkAddLogsHeaders}
            />
          </Box>
        </Collapse>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => push(userGroup)}>
          Push
        </Button>
        <Button
          style={{ color: '#f50057' }}
          size="small"
          onClick={() => clear(userGroup)}
        >
          Remove
        </Button>
        <Typography color="textSecondary" style={{ marginLeft: 'auto' }}>
          User Group ID: {userGroup.id}
        </Typography>
      </CardActions>
    </Card>
  );
}
UserGroupHistory.propTypes = {
  history: PropTypes.array,
  userGroup: PropTypes.object,
  clear: PropTypes.func,
  push: PropTypes.func
};
