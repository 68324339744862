import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmationDialog(props) {
  const { show, handleClose, handleOk, length } = props;
  const [open, setOpen] = useState(show);
  useEffect(() => {
    setOpen(show);
  }, [show]);

  const strings = Object.freeze({
    SINGLE: '',
    MULTIPLE: 's'
  });

  const stringToUse = length > 1 ? strings.MULTIPLE : strings.SINGLE;
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          Deactivate User{stringToUse}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you click OK, {length} user{stringToUse} will be deactivated
          </DialogContentText>
          <DialogContentText>
            This can be backtracked manually in trello.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ConfirmationDialog.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
  length: PropTypes.number
};
