import React, { useEffect } from 'react';
import appendScript from '../helpers/appendScript';

const Auth = () => {
  // eslint-disable-next-line no-undef
  const trelloPowerUp = TrelloPowerUp.iframe();

  const tokenLooksValid = (token) => {
    return /^[0-9a-f]{64}$/.test(token);
  };

  const authorizeOpts = {
    height: 680,
    width: 580,
    validToken: tokenLooksValid
  };

  const authClick = () => {
    trelloPowerUp
      .authorize(window.environment.REACT_APP_OAUTH_URL, authorizeOpts)
      .then((token) => {
        localStorage.setItem('authToken', token);
        return trelloPowerUp.set('member', 'private', 'authToken', token);
      })
      .then(() => {
        // now that the token is stored, we can close this popup
        // you might alternatively choose to open a new popup
        return trelloPowerUp.closePopup();
      });
  };

  useEffect(() => {
    // Update the document title using the browser API
    appendScript('https://p.trellocdn.com/power-up.min.js');
  }, []);

  return (
    <div id="content">
      <p>We need you to authorize first!</p>
      <button
        id="authorize"
        type="submit"
        className="mod-primary"
        onClick={authClick}
      >
        Authorize
      </button>
    </div>
  );
};

export default Auth;
