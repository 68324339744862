import { useState, useCallback } from 'react';

const InitialState = true;

export default function useOrganization() {
  const [loading, setLoading] = useState(InitialState);

  const handleLoaded = useCallback(
    (value) => {
      setLoading(value);
    },
    [loading]
  );

  return { loading, handleLoaded };
}
