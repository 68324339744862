import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CSVButton from './CSVButton.jsx';
import { CustomFieldsPushReportHeaders } from '../helpers/Headers';

export default function ResultDialog({
  show,
  onCloseClicked,
  text,
  data,
  label
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <Dialog
      open={open}
      onClose={onCloseClicked}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Download Report as CSV</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CSVButton
          headers={CustomFieldsPushReportHeaders}
          data={data}
          name={`CustomFields${label}Report`}
        />
        <Button onClick={onCloseClicked} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ResultDialog.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array,
  show: PropTypes.bool,
  onCloseClicked: PropTypes.func
};
