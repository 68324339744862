import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function UserGroupConfirmDelete(props) {
  const { show, userGroup, deleteGroup, handleDialogClose } = props;
  const { group_name: name, id } = userGroup;

  const onCancelClick = () => {
    handleDialogClose();
  };

  const onOkClick = () => {
    handleDialogClose();
    deleteGroup(id);
  };

  return (
    <>
      <Dialog
        open={show}
        onClose={onCancelClick}
        aria-labelledby="form-dialog-title"
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          Delete User Group : {name || 'name not specified'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you click OK, this group will be deleted, its users will be
            removed from all boards they were pushed to.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelClick} color="primary">
            Cancel
          </Button>
          <Button onClick={onOkClick} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

UserGroupConfirmDelete.propTypes = {
  show: PropTypes.bool,
  deleteGroup: PropTypes.func,
  name: PropTypes.string,
  userGroup: PropTypes.object,
  handleDialogClose: PropTypes.func
};

export default UserGroupConfirmDelete;
