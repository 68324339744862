import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Selector from '../Selector.jsx';
import useSelection from '../../helpers/useSelection.jsx';
import useLoading from '../../helpers/useLoading.jsx';
import useTotal from '../overview/useTotal.jsx';
import Users from '../overview/Users/Users.jsx';
import Boards from '../overview/Boards/Boards.jsx';
import { OverviewSelectorOptions } from '../../helpers/SelectorOptions';
import Loading from '../Loading.jsx';

function renderSwitch(param, handleTotalChange, handleLoaded) {
  switch (param) {
    case 'Users':
      return (
        <Users
          handleTotalChange={handleTotalChange}
          handleLoaded={handleLoaded}
        />
      );
    case 'Workspace':
      return <div />;
    case 'Boards':
      return (
        <Boards
          handleTotalChange={handleTotalChange}
          handleLoaded={handleLoaded}
        />
      );
    default:
      return (
        <Users
          handleTotalChange={handleTotalChange}
          handleLoaded={handleLoaded}
        />
      );
  }
}

const initialValues = Object.freeze({
  SELECTION: 'Users'
});

export default function Overview() {
  const { selection, handleChange } = useSelection(initialValues.SELECTION);
  const { total, handleTotalChange } = useTotal();
  const { loading, handleLoaded } = useLoading();

  useEffect(() => {
    handleTotalChange(0);
    handleLoaded(true);
  }, [selection]);

  return (
    <Grid container alignItems="center" spacing={0}>
      {loading && <Loading />}
      <Grid item xs={12}>
        <Selector
          total={total}
          selection={selection}
          handleChange={handleChange}
          options={OverviewSelectorOptions}
        />
      </Grid>
      <Grid item xs={12}>
        {renderSwitch(selection, handleTotalChange, handleLoaded)}
      </Grid>
    </Grid>
  );
}
